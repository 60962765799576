import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import CardMedia from "@material-ui/core/CardMedia";
import CardActionArea from "@material-ui/core/CardActionArea";
import Tooltip from "@material-ui/core/Tooltip";
import nextId from "react-id-generator";
import ContentCopy from "@material-ui/icons/FileCopyOutlined";
import PlayCircleOutline from "@material-ui/icons/PlayCircleOutline";
import PauseCircleOutline from "@material-ui/icons/PauseCircleOutline";
import ShoppingCart from "@material-ui/icons/ShoppingCartOutlined";
import algo from "../assets/images/algo.png";
import algoWhite from "../assets/images/algo-white.png";

const styles = (theme) => ({
  card: {
    maxWidth: "97%",
    minWidth: "70%",
    width: "80%",
    margin: "auto",
    borderRadius: 10,
    position: "relative",
    marginTop: 0,
    marginTop: 10,
    height: "99%",
    maxHeight: "99%",
  },
  title: {
    margin: `${theme.spacing(3)}px 0 ${theme.spacing(2)}px`,
    color: theme.palette.protectedTitle,
    textAlign: "center",
    fontSize: "1.2em",
  },
  avatar: {
    width: 60,
    height: 60,
    // marginLeft:'35%',
    marginBottom: "10%",
  },
  cardContent: {
    textAlign: "left",
    minHeight: 122,
    "&:last-child": {
      paddingBottom: 0,
    },
  },
  cardContentRoot: {
    paddingBottom: 5,
  },
  dataTitle: {
    fontSize: "0.8em",
    fontWeight: "bold",
    display: "inline",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.7em",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.7em",
    },
  },
  dataDesc: {
    fontSize: "0.8em",
    display: "inline",
    wordBreak: "break-word",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.6em",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.6em",
    },
  },
  btn: {
    borderRadius: 20,
    border: "1px solid #333333",
  },
  badge: {
    backgroundColor: "#fce1e4",
    borderRadius: "40px",
    textAlign: "center",
    display: "inline-block",
    marginBottom: 10,
    marginRight: 5,
    [theme.breakpoints.down("xs")]: {
      marginBottom: 8,
    },
    [theme.breakpoints.down("lg")]: {
      marginBottom: 10,
    },
    [theme.breakpoints.down("md")]: {
      marginBottom: 2,
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: 4,
    },
  },
  price: {
    color: "#585555",
    fontSize: 20,
    fontWeight: "bold",
    display: "inline",
  },
  algoImg: {
    width: 20,
    display: "inline",
  },
  badgeSpan: {
    color: "#434242",
    padding: "0px 11px",
    fontSize: 13,
    lineHeight: 2,
  },
  cardMediaBadgeSpan: {
    color: "#ffffff",
    padding: "0px 11px",
    fontSize: 13,
    lineHeight: 2,
  },
  cardTitle: {
    position: "absolute",
    top: "16%",
    left: "21%",
    width: "100%",
    textAlign: "left",
    color: "#ffffff",
    [theme.breakpoints.down("xs")]: {
      left: "21%",
    },
    [theme.breakpoints.down("lg")]: {
      left: "21%",
    },
    [theme.breakpoints.down("md")]: {
      left: "27%",
    },
    [theme.breakpoints.down("sm")]: {
      left: "25%",
    },
  },
  cardMediaGrid: {
    position: "absolute",
    bottom: "0",
    backgroundImage: "linear-gradient(to right, #2f2d2d99 10% ,#c8c6c624 )",
  },
  cardMediaGriditem: {
    height: 69,
  },
  ownerAvatar: {
    position: "absolute",
    top: "20%",
    left: "5%",
    textAlign: "left",
  },
  ownerName: {
    position: "absolute",
    top: "57%",
    left: "21%",
    textAlign: "left",
    fontSize: 12,
    color: "#ffffff",
    [theme.breakpoints.down("xs")]: {
      left: "21%",
    },
    [theme.breakpoints.down("lg")]: {
      left: "21%",
    },
    [theme.breakpoints.down("md")]: {
      left: "27%",
    },
    [theme.breakpoints.down("sm")]: {
      left: "25%",
    },
  },
  playIcon: {
    color: "#ffffff",
    fontSize: 45,
    "&:hover": {
      color: "#fd9235fc",
    },
  },
  disabledPlayIcon: {
    color: "#a39f9f",
    fontSize: 45,
  },
  iconButton: {
    position: "absolute",
    top: "0%",
    right: "0%",
  },
  cardMediaBadge: {
    position: "absolute",
    top: "5%",
    right: "4%",
    borderRadius: "40px",
    textAlign: "center",
    display: "inline-block",
  },
  buyBtn: {
    position: "absolute",
    top: "0%",
    left: "1%",
    display: "inline-block",
  },
  buyIcon: {
    color: "#ffffff",
    '&:hover':{
      color: "#fd9235fc",
    }
  },
});

class TileWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedAsset: {},
      selectedAssetId: "",
      accordionExpanded: false,
      isPreviewPlay: false,
      previewAudio: "",
    };
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
    this.handleClickOpenCard = this.handleClickOpenCard.bind(this);
    this.handleTogglePreview = this.handleTogglePreview.bind(this);
    this.handleBuyClick = this.handleBuyClick.bind(this);
  }

  handleClickOpenCard(id) {
    const { setIsTileView, assets } = this.props;
    let selectedAsset = assets.find((asset) => {
      if (asset._id === id) {
        return asset;
      }
    });
    setIsTileView(false);
  }

  handleCloseDialog() {
    const { setAssetModalOpen } = this.props;
    this.setState({
      isDeleteModalOpen: false,
      isEditMode: false,
      selectedAsset: {},
    });
    setAssetModalOpen(false);
  }


  handleBuyClick() {}

  handleTogglePreview(url) {   
    if(url.toLowerCase().indexOf('https://')>-1 || url.toLowerCase().indexOf('ipfs://')>-1){
      if (this.state.isPreviewPlay) {
        window.audioElement.pause();
        window.audioElement.currentTime = 0;
        this.setState({ isPreviewPlay: !this.state.isPreviewPlay, previewAudio: url });     
      } else {
        window.audioElement = new Audio(url);
        window.audioElement.play();
        this.setState({ isPreviewPlay: !this.state.isPreviewPlay, previewAudio: url });
      }
    }
    
    
  }

  render() {
    const { classes, setSelectedAsset, assets,isDark } =
      this.props;
    const { isPreviewPlay, previewAudio } = this.state;

    return (
      <>
        <Grid container spacing={1}>
          {assets &&
            assets.map((asset) => (
              <Grid
                key={nextId()}
                item
                xs={12}
                sm={6}
                md={3}
                style={{ display: "flex" }}
              >
                <Card className={classes.card} style={{backgroundColor: isDark && '#1d1d1d', color: isDark && '#ffffff'}}>
                <CardActionArea onClick={() => setSelectedAsset(asset, true)}>
                  <div style={{ position: "relative" }}>
                    <CardMedia
                      component="img"
                      height="250"
                      image={asset.avatar}
                      alt="avatar"
                    />
                    {asset.isAuction || asset.isSale ? (
                      <div
                        className={classes.cardMediaBadge}
                        style={{ backgroundImage: 'linear-gradient(to left , #fa5305c7 , #5e5a5adb)' }}
                      >
                        <span className={classes.cardMediaBadgeSpan}>
                          {asset.isAuction
                            ? "Auction"
                            : asset.isSale
                            ? "Sale"
                            : null}
                        </span>
                      </div>
                    ) : null}
                    <IconButton
                      className={classes.buyBtn}
                      onClick={this.handleBuyClick}
                    >
                      <ShoppingCart
                        className={classes.buyIcon}
                        fontSize="large"
                      />
                    </IconButton>
                    <Grid container className={classes.cardMediaGrid}>
                      <Grid
                        item
                        xs={6}
                        sm={6}
                        md={6}
                        className={classes.cardMediaGriditem}
                      >
                        <Avatar
                          src={asset.owner.avatar}
                          className={classes.ownerAvatar}
                        />
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="h5"
                          className={classes.cardTitle}
                        >
                          {asset.name.length > 7
                            ? asset.name.substring(0, 6) + "..."
                            : asset.name}
                        </Typography>
                        <br />
                        <br />
                        <Typography gutterBottom className={classes.ownerName}>
                        {`@ ${asset.owner.name}`}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sm={6}
                        md={6}
                        style={{ textAlign: "right" }}
                      >
                      {asset.hasPreview && <IconButton
                          className={classes.iconButton}
                          onClick={(event) => {
                              event.preventDefault()
                              event.stopPropagation()
                              this.handleTogglePreview(asset.previewURL)}
                          }
                        >
                          {isPreviewPlay ? (
                            <PauseCircleOutline className={classes.playIcon} />
                          ) : (
                            <PlayCircleOutline
                              className={
                                asset.hasPreview
                                  ? classes.playIcon
                                  : classes.disabledPlayIcon
                              }
                            />
                          )}
                        </IconButton> }
                     
                      </Grid>
                    </Grid>
                  </div>
                 
                    <CardContent
                      className={classes.cardContent}
                      classes={{ root: classes.cardContentRoot }}
                    >
                      <Grid container>
                        <Grid item xs={6} sm={6} md={6}>
                          <Typography className={classes.dataDesc}>
                            {asset._id}
                          </Typography>
                          <IconButton size="small">
                            <ContentCopy fontSize="small" style={{color: isDark && '#ffffff'}}/>
                          </IconButton>
                          <br />
                          <Tooltip title={asset.description} arrow>
                            <Typography className={classes.dataDesc}>
                              {asset.description.length > 65
                                ? asset.description.substring(0, 60) + "..."
                                : asset.description}
                            </Typography>
                          </Tooltip>
                          <br />
                          <Typography className={classes.dataTitle}>
                            Size:
                          </Typography>
                          <Typography className={classes.dataDesc}>
                            {" "}
                            {asset.size}
                          </Typography>
                          <br />
                          <Typography className={classes.dataTitle}>
                            Length:
                          </Typography>
                          <Typography className={classes.dataDesc}>
                            {" "}
                            {asset.length}
                          </Typography>
                          <br />
                          <Typography className={classes.dataTitle}>
                            Created on:
                          </Typography>
                          <Typography className={classes.dataDesc}>
                            {" "}
                            {asset.createdOn}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          sm={6}
                          md={6}
                          style={{ textAlign: "right" }}
                        >
                          <div>
                            <Typography className={classes.price} style={{color: isDark && '#fff' }}>
                              {asset.price}
                            </Typography>
                            <img src={isDark ? algoWhite : algo} className={classes.algoImg} />
                          </div>
                          {asset.isFractional && (
                            <>
                              <div
                                className={classes.badge}
                                style={{ backgroundColor: "#fce1e4" }}
                              >
                                <span className={classes.badgeSpan}>
                                  Fractional
                                </span>
                              </div>
                              <div className={classes.badge}>
                                <span className={classes.badgeSpan}>
                                  {asset.fractions}
                                </span>
                              </div>
                            </>
                          )}
                          {asset.isCollection && (
                            <div
                              className={classes.badge}
                              style={{ backgroundColor: "#daeaf6" }}
                            >
                              <span className={classes.badgeSpan}>
                                {asset.collectionType === "album"
                                  ? "Album"
                                  : asset.collectionType === "playlist"
                                  ? "Playlist"
                                  : "Folder"}
                              </span>
                            </div>
                          )}
                          <div
                            className={classes.badge}
                            style={{ backgroundColor: "#e8dff5" }}
                          >
                            <span className={classes.badgeSpan}>
                              {asset.filType === "mp4"
                                ? "mp4"
                                : asset.collectionType === "aac"
                                ? "aac"
                                : "wave"}
                            </span>
                          </div>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
        </Grid>
      </>
    );
  }
}
TileWidget.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  isAssetModalOpen: PropTypes.bool.isRequired,
  setAssetModalOpen: PropTypes.func.isRequired,
  setIsTileView: PropTypes.func.isRequired,
  assets: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string,
      avatar: PropTypes.string,
      isFractional: PropTypes.bool,
      fractions: PropTypes.number,
      creator: PropTypes.string,
      createdOn: PropTypes.string,
      metadata: PropTypes.array,
      isAuction: PropTypes.bool,
      isSale: PropTypes.bool,
      hasPreview: PropTypes.bool,
      price: PropTypes.string,
      owner: PropTypes.object,
      size: PropTypes.string,
      length: PropTypes.string,
    })
  ).isRequired,
  setSelectedAsset: PropTypes.func.isRequired,
  isSelectedAssetOpen: PropTypes.bool.isRequired,
  isDark: PropTypes.bool.isRequired,
};
export default withStyles(styles)(TileWidget);
