import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Card, CardContent, Typography, Tooltip, TextField } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import FileCopy from "@material-ui/icons/FileCopyOutlined";
import QrCodelogo from "@material-ui/icons/DashboardOutlined";
import algoLogo from "../assets/images/algo.png";
import algoWhite from "../assets/images/algo-white.png";
import MyAlgo from "@randlabs/myalgo-connect";
import { toDataURL } from "qrcode";
import { AccountBalance } from "@material-ui/icons";

const styles = (theme) => ({
  addressField: {
    minWidth: "90%",
    width: "100%",
    marginBottom: '10%',
    verticalAlign: "middle",
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#000",
    },
  },
  addressFieldDark: {
    minWidth: "90%",
    width: "100%",
    marginBottom: '10%',
    verticalAlign: "middle",
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#fff",
    },
  },
  addressInput: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.6em",
    },
  },
  addressInputDark: {
    color: "#fff",
    borderColor: "#ffff",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.6em",
    },
  },
  mainDiv: {
    marginTop: "50px",
    margin:20,
  },
  balance: {
    display: "inline",
  },
  algoImg: {
    width: 30,
    display: "inline",
  },
});
class MyAlgoWallet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      wallet: window.localStorage.getItem("alpos-customer-wallet") || "...",
      label: "Customer Wallet Name",
      note: "",
      xnote: false,
      html: false,
      amount: 0,
      asset: "15993946",
      inverse: false,
      margin: 4,
      uri: "algorand://",
      walletUri: "algorand://",
      errorLevel: "high",
      version: "auto",
      dataURL: algoLogo,
      walletDataURL: algoLogo,
      showOptIn: false,
      isWalletQrModalOpen: false,
      isAssetQrModalOpen: false,
      isCheckAsset: false,
      optInList: [],
      assetsOpt: [],
      assetsCreate: [],
      assetOK: false,
      walletOK: true,
      isWalletOptInAsset: false,
      darkColor: "#000",
      lightColor: "#FFF",
      isNoteHex: false,
      isNoteBase64: false,
      isNoteJson: false,
      isNoteTxt: true,
      isFilePickerModalOpen: false,
      balance: 0,
      heldAssetsBalance: 0,
      createdAssetsBalance: 0,
      hasCamera: false,
      hasFlash: false,
      isQrPickerModalOpen: false,
      scannedQrCodeTxt: "...",
      assetsCreated: [],
      assetsHeld: [],
      trxPayment: [],
      trxTransfer: [],
    };
    this.generateWalletQRCode = this.generateWalletQRCode.bind(this);
    this.myAlgoWallet = null;
    this.fetchWalletInfo = this.fetchWalletInfo.bind(this);
    this.myAlgoConnect = this.myAlgoConnect.bind(this);
  }

  generateWalletQRCode() {
    let {
      wallet,
      label,
      inverse,
      version,
      margin,
      errorLevel,
      lightColor,
      darkColor,
    } = this.state;
    const errorCorrectionLevel = errorLevel;
    const color = { light: lightColor, dark: darkColor };

    const opts = {
      inverse,
      version,
      margin,
      errorCorrectionLevel,
      color,
    };
    let algorandURI = "";

    algorandURI = `algorand://${wallet}?label=${label}`;

    opts.mode = "Auto";
    toDataURL(algorandURI, opts)
      .then((res) => {
        this.setState({ walletDataURL: res[0], walletUri: res[1] });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  async myAlgoConnect() {
    try {
      this.myAlgoWallet =
        this.myAlgoWallet === null ? new MyAlgo() : this.myAlgoWallet;
      const accounts = await this.myAlgoWallet.connect();
      console.log(accounts[0]);
      this.setState({ wallet: accounts[0].address });
    } catch (err) {
      console.error(err);
    }
  }

  fetchWalletInfo() {
    const { wallet } = this.state;
    // const this = this;
    const url = `https://testnet.algoexplorerapi.io/v2/accounts/${wallet}`;
    const urlTrx = `https://testnet.algoexplorerapi.io/idx2/v2/accounts/${wallet}/transactions?limit=10`;
    window
      .fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          if (data.address === wallet) {
            this.setState({
              assetsHeld: data.assets,
              assetsCreated: data["created-assets"],
              balance: data.amount / 1000000,
              heldAssetsBalance: data.assets.length,
              createdAssetsBalance: data["created-assets"].length,
            });
          }
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    window
      .fetch(urlTrx, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          if (data.transactions) {
            this.setState({
              trxPayment: data.transactions.filter(
                (trx) => !!trx["payment-transaction"]
              ),
              trxTransfer: data.transactions.filter(
                (trx) => !!trx["asset-transfer-transaction"]
              ),
            });
          }
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  render() {
    const { wallet, walletOK, balance } = this.state;
    const { classes,isDark } = this.props;

    return (
      <div className={classes.mainDiv}>
          <TextField
          id="wallet"
          label="Wallet"
          variant="outlined"
          value={wallet}
          className={isDark ? classes.addressFieldDark : classes.addressField}
          margin="normal"
          onChange={(event) => {
              this.setState({ wallet: event.target.value }, () => {
                this.fetchWalletInfo();
              });
            }}
          InputProps={{
            classes: {
              input: isDark ? classes.addressInputDark : classes.addressInput,
            },
            endAdornment: (
              <>
                <Tooltip title="Copy wallet address">
                  <IconButton
                    onClick={() => {
                      var copyText = document.getElementById("wallet");
                      copyText.select();

                      document.execCommand("copy");
                      alert("Copied the wallet address: " + copyText.value);
                    }}
                    edge="end"
                  >
                    <FileCopy
                      style={{
                        color: isDark ? "#ffffff" : "#000000",
                      }}
                    />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Show QRCode">
                  <IconButton
                    onClick={() => {
                      this.generateWalletQRCode();
                    }}
                    edge="end"
                  >
                    <QrCodelogo
                      style={{
                        color: isDark ? "#ffffff" : "#000000",
                      }}
                    />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  title="Get address from MyAlgo wallet"
                >
                  <IconButton
                    id="myalgo-connect-button"
                    onClick={() => {
                        this.myAlgoConnect();
                      }}
                    edge="end"
                  >
                    {walletOK ? (
                        <img
                          width={32}
                          height={32}
                          style={{
                            backgroundColor: "#ddf4dd",
                            padding: 5,
                            borderRadius: 50,
                          }}
                          src={algoLogo}
                        />
                      ) : (
                        <img
                          width={32}
                          height={32}
                          style={{
                            padding: 5,
                            borderRadius: 50,
                          }}
                          src={isDark ? algoWhite : algoLogo}
                        />
                      )}
                  </IconButton>
                </Tooltip>
              </>
            ),
          }}
          InputLabel={{
            root: classes.inputLabel,
          }}
          InputLabelProps={{
            style: { color: isDark ? "#fff" : "#000" },
          }}
        />
         
          <Typography variant="h5" className={classes.balance} style={{color: isDark && '#fff'}}>
            Balance:{balance}
          </Typography>
          <img src={isDark ? algoWhite : algoLogo} className={classes.algoImg} />
      </div>
    );
  }
}
MyAlgoWallet.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  activeUser: PropTypes.shape({
    _id: PropTypes.string,
    email: PropTypes.string,
    displayName: PropTypes.string,
    avatar: PropTypes.string,
  }).isRequired,
  onEditProfileClick: PropTypes.func.isRequired,
  onLogoutClick: PropTypes.func.isRequired,
  isDrawerOpen: PropTypes.bool.isRequired,
  closeDrawer: PropTypes.func.isRequired,
  isDark: PropTypes.bool.isRequired,
};
export default withStyles(styles)(MyAlgoWallet);
