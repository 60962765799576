import React from "react";
import PropTypes from "prop-types";
import { withStyles, styled } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import ImageUploading from "react-images-uploading";
import { Add, Delete, Person } from "@material-ui/icons";
import Edit from "@material-ui/icons/Edit";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Switch from "@material-ui/core/Switch";
import day from "../assets/images/day.png";
import night from "../assets/images/night.png";

const styles = (theme) => ({
  title: {
    marginBottom: "10%",
  },
  displayName: {
    verticalAlign: "middle",
    marginLeft: "10px",
    fontSize: "1em",
    color: "#ffffff",
    fontWeight: "bold",
    cursor: "pointer",
    opacity: "1",
    lineHeight: "1em",
  },
  displayNameOffline: {
    verticalAlign: "middle",
    marginLeft: "10px",
    fontSize: "1em",
    color: "#1b334b",
    fontWeight: "bold",
    opacity: "0.6",
    cursor: "default",
    lineHeight: "1em",
  },
  text: {
    color: "#ffffff",
    fontSize: 10,
    display: "block",
  },
  right: {
    float: "right",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: "#555555",
  },
  logoutBtn: {
    backgroundColor: "#f5efed",
    color: "#d94804",
    borderRadius: 25,
    textTransform: "none",
    width: "100%",
    maxheight: "100%",
    minHeight: "100%",
    marginTop: 20,
    "&:hover": {
      backgroundColor: "#d94804",
      color: "#ffffff",
    },
  },
  textField: {
    width: "100%",
    marginLeft: theme.spacing(1),
    "&.MuiTextField-root": {
      color: "#f98900",
    },
    // display: "block",
  },
  margin: {
    margin: theme.spacing(1),
  },
  saveBtn: {
    backgroundColor: "#f98900",
    color: "#ffffff",
    boxShadow: "none",
    borderRadius: 20,
    textTransform: "none",
    width: "17%",
    height: 32,
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
    "&:hover": {
      backgroundColor: "#f5efed",
      color: "#f98900",
    },
  },
  cancelBtn: {
    backgroundColor: "#f5efed",
    color: "#d94804",
    borderRadius: 20,
    boxShadow: "none",
    textTransform: "none",
    width: "17%",
    height: 32,
    marginTop: theme.spacing(1),
    "&:hover": {
      backgroundColor: "#d94804",
      color: "#ffffff",
    },
  },
  image: {
    width: "100px",
  },
  userIcon: {
    fontSize: 90,
    borderRadius: 50,
    backgroundColor: "#f6fbff",
  },
  addIcon: {
    marginLeft: 5,
    marginTop: 15,
    backgroundColor: "#f5efed",
    color: "#d94804",
    "&:hover": {
      backgroundColor: "#d94804",
      color: "#ffffff",
    },
  },
  textFieldRootDark: {
    color: "#fff",
    "& .MuiTextField-root": {
      borderColor: "#fff",
    },
'&::after':{
  borderColor: "#fff",

}
  },
  textFieldRoot: {
    color: "#000",
  },
  mainDiv: {
    margin: "10px",
  },
});

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 52,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      marginLeft: 11,
      "& + .MuiSwitch-track": {
        backgroundColor: "#cac9c9",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#f44336",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.7,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
    backgroundRepeat: "no-repeat",
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#cac9c9",
    opacity: 1,
    [theme.breakpoints.down("xs")]: {
      backgroundColor: "#E9E9EA",
    },
    "&:after, &:before": {
      color: "white",
      fontSize: "11px",
      position: "absolute",
      top: "6px",
    },
    "&:after": {
      content: `url(${day})`,
      backgroundImage: `url(${day})`,
      backgroundSize: "17px",
      backgroundRepeat: "no-repeat",
      left: "4px",
      top: "4px",
    },
    "&:before": {
      content: "''",
      backgroundImage: `url(${night})`,
      backgroundSize: "17px",
      backgroundRepeat: "no-repeat",
      right: "5px",
      top: "4px",
      padding: 9,
    },
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

class UserProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDarkMode: false,
      isEditMode: false,
      isUploaderMode: true,
      email: (props.activeUser && props.activeUser.email) || "",
      displayName: (props.activeUser && props.activeUser.displayName) || "",
      avatar: (props.activeUser && props.activeUser.avatar) || "",
      images: (props.activeUser && [props.activeUser.avatar]) || [],
    };
    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.handleLogoutClick = this.handleLogoutClick.bind(this);
    this.handleAvatarChange = this.handleAvatarChange.bind(this);
  }

  // componentDidMount() {
  //   this.setState({
  //     email: this.props.activeUser && this.props.activeUser.email,
  //     displayName: this.props.activeUser && this.props.activeUser.displayName,
  //     avatar: this.props.activeUser && this.props.activeUser.avatar || "",
  //     images: this.props.activeUser && [this.props.activeUser.avatar] || [],
  //     isDarkMode: this.props.activeUser && this.props.isDark,
  //   });
  // }

  // componentDidUpdate(prevProps) {
  //   if (this.props.activeUser.email !== prevProps.activeUser.email
  //     || this.props.activeUser.avatar !== prevProps.activeUser.avatar
  //     || this.props.activeUser.displayName !== prevProps.activeUser.displayName
  //     || this.props.isDark !== prevProps.isDark) {
  //     this.setState({
  //       email: this.props.activeUser.email,
  //       displayName: this.props.activeUser.displayName,
  //       avatar: this.props.activeUser.avatar,
  //       isDarkMode: this.props.isDark,
  //     });
  //   }
  // }

  handleInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      isEditMode: true,
    });
  };

  handleSaveClick = () => {
    this.props.onEditProfileClick(
      {
        email: this.state.email,
        displayName: this.state.displayName,
        avatar:
          this.state.avatar && this.state.avatar !== null
            ? this.state.avatar
            : this.props.activeUser.avatar,
        isDarkMode: this.state.isDarkMode,
      },
      this.props.t
    );
    this.props.closeDrawer();
    this.setState({ isEditMode: false });
  };

  handleCancelClick = () => {
    this.setState({ isEditMode: false });
  };

  handleLogoutClick = () => {
    this.props.onLogoutClick(this.props.t);
    this.props.closeDrawer();
  };

  handleAvatarChange = (imageList, addUpdateIndex) => {
    if (imageList[0]) {
      if (imageList[0].data_url) {
        this.setState({
          avatar: imageList[0].data_url,
          isEditMode: true,
          isUploaderMode: true,
        });
      } else {
        this.setState({
          avatar: "",
          images: [],
          isEditMode: true,
          isUploaderMode: true,
        });
      }
    }
  };

  render() {
    const { avatar, displayName, email, isEditMode } = this.state;
    const { classes, activeUser, closeDrawer, isDark, setDarkMode } =
      this.props;
    const images = [avatar];
    return (
      <div className={classes.mainDiv}>
        <Typography
          variant="h6"
          style={{ color: isDark && "#fff", marginTop: "10%" }}
          className={classes.title}
        >
          User Profile
        </Typography>
        <Grid container direction="column" spacing={5}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <ImageUploading
              value={avatar}
              onChange={this.handleAvatarChange}
              maxNumber={1}
              dataURLKey="data_url"
            >
              {({
                imageList,
                onImageUpload,
                onImageRemove,
                isDragging,
                dragProps,
              }) => (
                <>
                  {avatar === "" || avatar == null ? (
                    <>
                      <div
                        className={classes.imageUploadDiv}
                        {...dragProps}
                        // style={isDragging ? { backgroundColor: defaultPallet[4] } : null}
                      >
                        <Person
                          className={classes.userIcon}
                          style={{ color: isDark && "#8b8989" }}
                        />
                      </div>

                      <IconButton
                        aria-label="Add image"
                        component="span"
                        className={classes.addIcon}
                        onClick={onImageUpload}
                      >
                        <Add />
                      </IconButton>
                    </>
                  ) : (
                    <>
                      <img
                        src={imageList}
                        alt=""
                        className={classes.image}
                        onClick={onImageUpload}
                      />
                      {isEditMode ? (
                        <div>
                          <Button
                            size="small"
                            onClick={() => onImageRemove(0)}
                            className={classes.cancelBtn}
                          >
                            Delete
                          </Button>
                          <Button
                            size="small"
                            onClick={this.handleSaveClick}
                            className={classes.saveBtn}
                          >
                            Save
                          </Button>
                          {/* <Button
                            size="small"
                            onClick={this.handleCancelClick}
                            className={classes.cancelBtn}
                          >
                            Cancel
                          </Button> */}
                          <div className={classes.margin} />
                        </div>
                      ) : null}
                    </>
                  )}
                </>
              )}
            </ImageUploading>{" "}
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            {/* <TextField
                name="displayName"
                placeholder={t('Edit your display name')}
                type="text"
                value={displayName}
                className={classes.textField}
                onChange={this.handleInputChange}
              /> */}
            <TextField
              name="email"
              placeholder="Edit your email address"
              type="text"
              value={email}
              className={classes.textField}
              onChange={this.handleInputChange}
              InputProps={{
                classes: {
                  input: isDark
                    ? classes.textFieldRootDark
                    : classes.textFieldRoot,
                    underline:classes.textFieldRootDark
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Typography
              variant="h6"
              style={{ color: isDark && "#fff" }}
              className={classes.title}
            >
              User preferences
            </Typography>
            <IOSSwitch
              sx={{ m: 1 }}
              className={classes.switchBtn}
              checked={isDark}
              onChange={() => setDarkMode(!isDark)}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <Button
              className={classes.logoutBtn}
              size="small"
              onClick={this.handleLogoutClick}
            >
              Sign Out
            </Button>
          </Grid>

          {/* <div className={classes.margin} /> */}
        </Grid>

        <div className={classes.margin} />
      </div>
    );
  }
}
UserProfile.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  activeUser: PropTypes.shape({
    _id: PropTypes.string,
    email: PropTypes.string,
    displayName: PropTypes.string,
    avatar: PropTypes.string,
  }).isRequired,
  onEditProfileClick: PropTypes.func.isRequired,
  onLogoutClick: PropTypes.func.isRequired,
  setDarkMode: PropTypes.func.isRequired,
  isDark: PropTypes.bool.isRequired,
};
export default withStyles(styles)(UserProfile);
