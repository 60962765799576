import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Drawer } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
import UserProfile from "../components/UserProfile";
import algoLogo from "../assets/images/algo.png";
import MyAlgo from "@randlabs/myalgo-connect";
import { toDataURL } from "qrcode";
import MyAlgoWallet from "./MyAlgoWallet";

const styles = (theme) => ({
  right: {
    float: "right",
  },
  drawerPaper: {
    width: 400,
  },
  drawerPaperDark: {
    width: 400,
    backgroundColor: '#222222',
    color: '#ffffff'
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: "#555555",
  },
  margin: {
    margin: theme.spacing(1),
  },
});
class CustomDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditMode: false,
      isUploaderMode: true,
      email: (props.activeUser && props.activeUser.email) || "",
      displayName: (props.activeUser && props.activeUser.displayName) || "",
      avatar: (props.activeUser && props.activeUser.avatar) || "",
      images: (props.activeUser && [props.activeUser.avatar]) || [],
    };
  }

  // componentDidMount() {
  //   this.setState({
  //     email: this.props.activeUser && this.props.activeUser.email,
  //     displayName: this.props.activeUser && this.props.activeUser.displayName,
  //     avatar: this.props.activeUser && this.props.activeUser.avatar || "",
  //     images: this.props.activeUser && [this.props.activeUser.avatar] || [],
  //     isDarkMode: this.props.activeUser && this.props.isDark,
  //   });
  // }

  // componentDidUpdate(prevProps) {
  //   if (this.props.activeUser.email !== prevProps.activeUser.email
  //     || this.props.activeUser.avatar !== prevProps.activeUser.avatar
  //     || this.props.activeUser.displayName !== prevProps.activeUser.displayName
  //     || this.props.isDark !== prevProps.isDark) {
  //     this.setState({
  //       email: this.props.activeUser.email,
  //       displayName: this.props.activeUser.displayName,
  //       avatar: this.props.activeUser.avatar,
  //       isDarkMode: this.props.isDark,
  //     });
  //   }
  // }

  render() {
    const { avatar, displayName, email, isLangMenuOpen, isEditMode } =
      this.state;
    const { classes, selectedItem, activeUser, isDrawerOpen, closeDrawer,isDark,setDarkMode } =
      this.props;

    return (
      <>
        <Drawer
          anchor="right"
          open={isDrawerOpen}
          onClose={closeDrawer()}
          classes={{ paper: isDark ? classes.drawerPaperDark : classes.drawerPaper }}
        >
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={closeDrawer()}
          >
            <Close style={{color: isDark && '#fff'}} />
          </IconButton>
          {selectedItem === "profile" ? <UserProfile isDark={isDark} setDarkMode={setDarkMode} /> : 
          <MyAlgoWallet isDark={isDark} />}
        </Drawer>
      </>
    );
  }
}
CustomDrawer.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  activeUser: PropTypes.shape({
    _id: PropTypes.string,
    email: PropTypes.string,
    displayName: PropTypes.string,
    avatar: PropTypes.string,
  }).isRequired,
  onEditProfileClick: PropTypes.func.isRequired,
  onLogoutClick: PropTypes.func.isRequired,
  isDrawerOpen: PropTypes.bool.isRequired,
  closeDrawer: PropTypes.func.isRequired,
  setDarkMode: PropTypes.func.isRequired,
  isDark: PropTypes.bool.isRequired,
};
export default withStyles(styles)(CustomDrawer);
