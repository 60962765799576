import React from "react";
import PropTypes from "prop-types";
import { withStyles, styled } from "@material-ui/core/styles";
import Header from "./Header";
import AuctionsView from "./AuctionsView";
import SalesView from "./SalesView";
import AssetsView from "./AssetsView";
import Grid from "@material-ui/core/Grid";
import img1 from "../assets/images/img1.png";
import img2 from "../assets/images/img2.png";
import img3 from "../assets/images/img3.png";
import ImgSample1 from "../assets/images/sample1.jpg";
import ImgSample2 from "../assets/images/sample2.jpg";
import ImgSample3 from "../assets/images/sample3.jpg";
import ImgSample4 from "../assets/images/sample4.jpg";
import ImgSample5 from "../assets/images/sample5.jpg";
import ImgSample6 from "../assets/images/sample6.jpg";
import ImgSample7 from "../assets/images/sample7.jpg";
import ImgSample8 from "../assets/images/sample8.jpg";
import ImgSample9 from "../assets/images/sample9.jpg";
import ImgSample10 from "../assets/images/sample10.jpg";
import ImgSample11 from "../assets/images/sample11.jpg";
import ImgSample12 from "../assets/images/sample12.jpg";
import ImgSample13 from "../assets/images/sample13.jpg";
import ImgSample14 from "../assets/images/sample14.jpg";
import ImgSample15 from "../assets/images/sample15.jpg";
import ImgSample16 from "../assets/images/sample16.jpg";
import ImgSample17 from "../assets/images/sample17.jpg";
import ImgSample18 from "../assets/images/sample18.jpg";
import ImgSample19 from "../assets/images/sample19.jpg";
import ImgSample20 from "../assets/images/sample20.jpg";
import ImgSample21 from "../assets/images/sample21.jpg";

const styles = (theme) => ({
  grid: {
    marginTop: 10,
    paddingRight: 20,
    paddingLeft: 20,
  },
  mainDiv:{
    backgroundColor: '#ffffff'
  },
  mainDivDark:{
    backgroundColor: '#1b1b1b'
  },
});

const myAssets = [
  {
    _id: 72,
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    name: "Sample test",
    avatar: ImgSample1,
    isFractional: true,
    fractions: 23,
    creator: "",
    createdAT: "",
    creationRound: "1234567",
    owner: { name: "SYI", avatar: img3 },
    isAuction: true,
    isSale: false,
    isCollection: true,
    collectionType: "album",
    fileType: "aac",
    hasPreview: true,
    price: 10,
    initialPrice: 12,
    extraMetadata: {
      artist: "SY",
      album: "Ljjh",
      year: "2021",
      genre: "Jaz",
      date: "",
      comment: "",
    },
    metadataURL: "",
    assetURL: "",
    size: 12,
    length: "1:40",
    tags: "",
    likes: 2,
    rank: 3,
    ASAID: 32,
    previewURL: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3',
    fileURL: "",
    fileName: "",
  },
  // {
  //   _id: 89,
  //   description:
  //     "sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  //   name: "Test 3",
  //   avatar: ImgSample2,
  //   isFractional: false,
  //   fractions: 3,
  //   creator: "",
  //   createdAT: "",
  //   creationRound: "1234567",
  //   owner: { name: "Gds", avatar: img2 },
  //   isAuction: true,
  //   isSale: false,
  //   isCollection: false,
  //   collectionType: "album",
  //   fileType: "aac",
  //   hasPreview: false,
  //   price: 7,
  //   initialPrice: 9,
  //   extraMetadata: {
  //     artist: "NP",
  //     album: "dg",
  //     year: "2020",
  //     genre: "Jaz",
  //     date: "",
  //     comment: "",
  //   },
  //   metadataURL: "",
  //   assetURL: "",
  //   size: 12,
  //   length: "1:54",
  //   tags: "",
  //   likes: 74,
  //   rank: 1,
  //   ASAID: 22,
  //   previewURL: "https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3",
  //   fileURL: "",
  //   fileName: "",
  // },
  // {
  //   _id: 178,
  //   description: "incididunt ut labore et dolore magna",
  //   name: "Testone",
  //   avatar: ImgSample3,
  //   isFractional: true,
  //   fractions: 2,
  //   creator: "",
  //   createdAT: "",
  //   creationRound: "1234567",
  //   owner: { name: "Javk", avatar: img1 },
  //   isAuction: false,
  //   isSale: true,
  //   isCollection: true,
  //   collectionType: "album",
  //   fileType: "aac",
  //   hasPreview: true,
  //   price: 12,
  //   initialPrice: 11,
  //   extraMetadata: {
  //     artist: "Nkj",
  //     album: "DS",
  //     year: "2020",
  //     genre: "",
  //     date: "",
  //     comment: "",
  //   },
  //   metadataURL: "",
  //   assetURL: "",
  //   size: 3,
  //   length: "1:40",
  //   tags: "",
  //   likes: 54,
  //   rank: 2,
  //   ASAID: 42,
  //   previewURL: "https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3",
  //   fileURL: "",
  //   fileName: "",
  // },
  // {
  //   _id: 22,
  //   description: "incididunt ut labore et dolore magna",
  //   name: "MDOR",
  //   avatar: ImgSample4,
  //   isFractional: false,
  //   fractions: 2,
  //   creator: "",
  //   createdAT: "",
  //   creationRound: "1234567",
  //   owner: { name: "Gds", avatar: img2 },
  //   isAuction: true,
  //   isSale: false,
  //   isCollection: true,
  //   collectionType: "album",
  //   fileType: "aac",
  //   hasPreview: false,
  //   price: 18,
  //   initialPrice: 14,
  //   extraMetadata: {
  //     artist: "Nkj",
  //     album: "DS",
  //     year: "2020",
  //     genre: "",
  //     date: "",
  //     comment: "",
  //   },
  //   metadataURL: "",
  //   assetURL: "",
  //   size: 3,
  //   length: "1:40",
  //   tags: "",
  //   likes: 54,
  //   rank: 2,
  //   ASAID: 42,
  //   previewURL: "https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3",
  //   fileURL: "",
  //   fileName: "",
  // },
  // {
  //   _id: 88,
  //   description:
  //     "sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  //   name: "RW",
  //   avatar: ImgSample5,
  //   isFractional: false,
  //   fractions: 3,
  //   creator: "",
  //   createdAT: "",
  //   creationRound: "1234567",
  //   owner: { name: "SYI", avatar: img3 },
  //   isAuction: true,
  //   isSale: false,
  //   isCollection: false,
  //   collectionType: "album",
  //   fileType: "aac",
  //   hasPreview: false,
  //   price: 7,
  //   initialPrice: 9,
  //   extraMetadata: {
  //     artist: "NP",
  //     album: "dg",
  //     year: "2020",
  //     genre: "Jaz",
  //     date: "",
  //     comment: "",
  //   },
  //   metadataURL: "",
  //   assetURL: "",
  //   size: 12,
  //   length: "1:54",
  //   tags: "",
  //   likes: 74,
  //   rank: 1,
  //   ASAID: 22,
  //   previewURL: "https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3",
  //   fileURL: "",
  //   fileName: "",
  // },
  // {
  //   _id: 72,
  //   description:
  //     "Lorem ipsum dolor sit amet, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  //   name: "DAGWSGFAA",
  //   avatar: ImgSample6,
  //   isFractional: true,
  //   fractions: 33,
  //   creator: "",
  //   createdAT: "",
  //   creationRound: "1234567",
  //   owner: { name: "SYI", avatar: img3 },
  //   isAuction: true,
  //   isSale: false,
  //   isCollection: true,
  //   collectionType: "album",
  //   fileType: "aac",
  //   hasPreview: true,
  //   price: 10,
  //   initialPrice: 12,
  //   extraMetadata: {
  //     artist: "SY",
  //     album: "Ljjh",
  //     year: "2021",
  //     genre: "Jaz",
  //     date: "",
  //     comment: "",
  //   },
  //   metadataURL: "",
  //   assetURL: "",
  //   size: 12,
  //   length: "1:40",
  //   tags: "",
  //   likes: 2,
  //   rank: 3,
  //   ASAID: 32,
  //   previewURL: "https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3",
  //   fileURL: "",
  //   fileName: "",
  // },
  // {
  //   _id: 48,
  //   description: "incididunt ut labore et dolore magna",
  //   name: "PPPP",
  //   avatar: ImgSample7,
  //   isFractional: false,
  //   fractions: 2,
  //   creator: "",
  //   createdAT: "",
  //   creationRound: "1234567",
  //   owner: { name: "Javk", avatar: img1 },
  //   isAuction: false,
  //   isSale: true,
  //   isCollection: false,
  //   collectionType: "album",
  //   fileType: "aac",
  //   hasPreview: true,
  //   price: 53,
  //   initialPrice: 50,
  //   extraMetadata: {
  //     artist: "Nkj",
  //     album: "DS",
  //     year: "2020",
  //     genre: "",
  //     date: "",
  //     comment: "",
  //   },
  //   metadataURL: "",
  //   assetURL: "",
  //   size: 3,
  //   length: "1:40",
  //   tags: "",
  //   likes: 54,
  //   rank: 2,
  //   ASAID: 42,
  //   previewURL: "https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3",
  //   fileURL: "",
  //   fileName: "",
  // },
  // {
  //   _id: 55,
  //   description: "incididunt ut labore et dolore magna",
  //   name: "VS",
  //   avatar: ImgSample8,
  //   isFractional: true,
  //   fractions: 2,
  //   creator: "",
  //   createdAT: "",
  //   creationRound: "1234567",
  //   owner: { name: "Javk", avatar: img1 },
  //   isAuction: false,
  //   isSale: true,
  //   isCollection: true,
  //   collectionType: "album",
  //   fileType: "aac",
  //   hasPreview: true,
  //   price: 4,
  //   initialPrice: 7,
  //   extraMetadata: {
  //     artist: "Nkj",
  //     album: "DS",
  //     year: "2020",
  //     genre: "",
  //     date: "",
  //     comment: "",
  //   },
  //   metadataURL: "",
  //   assetURL: "",
  //   size: 3,
  //   length: "1:40",
  //   tags: "",
  //   likes: 54,
  //   rank: 2,
  //   ASAID: 42,
  //   previewURL: "https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3",
  //   fileURL: "",
  //   fileName: "",
  // },
  // {
  //   _id: 81,
  //   description: "incididunt ut labore et dolore magna",
  //   name: "WRbvx",
  //   avatar: ImgSample9,
  //   isFractional: true,
  //   fractions: 2,
  //   creator: "",
  //   createdAT: "",
  //   creationRound: "1234567",
  //   owner: { name: "Gds", avatar: img2 },
  //   isAuction: false,
  //   isSale: true,
  //   isCollection: true,
  //   collectionType: "album",
  //   fileType: "aac",
  //   hasPreview: true,
  //   price: 12,
  //   initialPrice: 11,
  //   extraMetadata: {
  //     artist: "Nkj",
  //     album: "DS",
  //     year: "2020",
  //     genre: "",
  //     date: "",
  //     comment: "",
  //   },
  //   metadataURL: "",
  //   assetURL: "",
  //   size: 3,
  //   length: "1:40",
  //   tags: "",
  //   likes: 54,
  //   rank: 2,
  //   ASAID: 42,
  //   previewURL: "https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3",
  //   fileURL: "",
  //   fileName: "",
  // },
  // {
  //   _id: 14,
  //   description: "empor incididunt ut labore et dolore magna aliqua.",
  //   name: "Fsg",
  //   avatar: ImgSample10,
  //   isFractional: true,
  //   fractions: 33,
  //   creator: "",
  //   createdAT: "",
  //   creationRound: "1234567",
  //   owner: { name: "SYI", avatar: img3 },
  //   isAuction: false,
  //   isSale: true,
  //   isCollection: true,
  //   collectionType: "album",
  //   fileType: "aac",
  //   hasPreview: true,
  //   price: 10,
  //   initialPrice: 12,
  //   extraMetadata: {
  //     artist: "SY",
  //     album: "Ljjh",
  //     year: "2021",
  //     genre: "Jaz",
  //     date: "",
  //     comment: "",
  //   },
  //   metadataURL: "",
  //   assetURL: "",
  //   size: 12,
  //   length: "1:40",
  //   tags: "",
  //   likes: 2,
  //   rank: 3,
  //   ASAID: 32,
  //   previewURL: "https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3",
  //   fileURL: "",
  //   fileName: "",
  // },
  // {
  //   _id: 72,
  //   description:
  //     "Lorem ipeiusmod tempor incididunt ut labore et dolore magna aliqua.",
  //   name: "DAGWSGFAA",
  //   avatar: ImgSample11,
  //   isFractional: true,
  //   fractions: 33,
  //   creator: "",
  //   createdAT: "",
  //   creationRound: "1234567",
  //   owner: { name: "Gds", avatar: img2 },
  //   isAuction: false,
  //   isSale: true,
  //   isCollection: true,
  //   collectionType: "album",
  //   fileType: "aac",
  //   hasPreview: true,
  //   price: 15,
  //   initialPrice: 12,
  //   extraMetadata: {
  //     artist: "SY",
  //     album: "Ljjh",
  //     year: "2021",
  //     genre: "Jaz",
  //     date: "",
  //     comment: "",
  //   },
  //   metadataURL: "",
  //   assetURL: "",
  //   size: 12,
  //   length: "1:40",
  //   tags: "",
  //   likes: 2,
  //   rank: 3,
  //   ASAID: 32,
  //   previewURL: "https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3",
  //   fileURL: "",
  //   fileName: "",
  // },
  // {
  //   _id: 92,
  //   description:
  //     "Loetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  //   name: "LORE",
  //   avatar: ImgSample12,
  //   isFractional: true,
  //   fractions: 23,
  //   creator: "",
  //   createdAT: "",
  //   creationRound: "1234567",
  //   owner: { name: "SYI", avatar: img1 },
  //   isAuction: true,
  //   isSale: false,
  //   isCollection: true,
  //   collectionType: "album",
  //   fileType: "aac",
  //   hasPreview: true,
  //   price: 10,
  //   initialPrice: 12,
  //   extraMetadata: {
  //     artist: "SY",
  //     album: "Ljjh",
  //     year: "2021",
  //     genre: "Jaz",
  //     date: "",
  //     comment: "",
  //   },
  //   metadataURL: "",
  //   assetURL: "",
  //   size: 12,
  //   length: "1:40",
  //   tags: "",
  //   likes: 2,
  //   rank: 3,
  //   ASAID: 32,
  //   previewURL: "https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3",
  //   fileURL: "",
  //   fileName: "",
  // },
  // {
  //   _id: 4,
  //   description:
  //     "ing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  //   name: "SHIL",
  //   avatar: ImgSample13,
  //   isFractional: true,
  //   fractions: 23,
  //   creator: "",
  //   createdAT: "",
  //   creationRound: "1234567",
  //   owner: { name: "SYI", avatar: img3 },
  //   isAuction: false,
  //   isSale: true,
  //   isCollection: true,
  //   collectionType: "album",
  //   fileType: "aac",
  //   hasPreview: true,
  //   price: 10,
  //   initialPrice: 12,
  //   extraMetadata: {
  //     artist: "SY",
  //     album: "Ljjh",
  //     year: "2021",
  //     genre: "Jaz",
  //     date: "",
  //     comment: "",
  //   },
  //   metadataURL: "",
  //   assetURL: "",
  //   size: 12,
  //   length: "1:40",
  //   tags: "",
  //   likes: 2,
  //   rank: 3,
  //   ASAID: 32,
  //   previewURL: "https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3",
  //   fileURL: "",
  //   fileName: "",
  // },
  // {
  //   _id: 14,
  //   description: "incididunt ut labore et dolore magna",
  //   name: "BAKLU",
  //   avatar: ImgSample14,
  //   isFractional: true,
  //   fractions: 2,
  //   creator: "",
  //   createdAT: "",
  //   creationRound: "1234567",
  //   owner: { name: "Gds", avatar: img2 },
  //   isAuction: false,
  //   isSale: true,
  //   isCollection: true,
  //   collectionType: "album",
  //   fileType: "aac",
  //   hasPreview: true,
  //   price: 12,
  //   initialPrice: 11,
  //   extraMetadata: {
  //     artist: "Nkj",
  //     album: "DS",
  //     year: "2020",
  //     genre: "",
  //     date: "",
  //     comment: "",
  //   },
  //   metadataURL: "",
  //   assetURL: "",
  //   size: 3,
  //   length: "1:40",
  //   tags: "",
  //   likes: 54,
  //   rank: 2,
  //   ASAID: 42,
  //   previewURL: "https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3",
  //   fileURL: "",
  //   fileName: "",
  // },
];

const allAssets = [
  // {
  //   _id: 12345678,
  //   description: "",
  //   name: "testatk one",
  //   avatar: ImgSample15,
  //   isFractional: true,
  //   fractions: 23,
  //   creator: "",
  //   createdAT: "",
  //   creationRound: "1234567",
  //   owner: { name: "Gds", avatar: img2 },
  //   isAuction: false,
  //   isSale: true,
  //   isCollection: true,
  //   collectionType: "album",
  //   fileType: "aac",
  //   hasPreview: true,
  //   price: 10,
  //   initialPrice: 12,
  //   extraMetadata: {
  //     artist: "ban",
  //     album: "ad",
  //     year: "",
  //     genre: "",
  //     date: "",
  //     comment: "",
  //   },
  //   metadataURL: "",
  //   assetURL: "",
  //   size: 12,
  //   length: "1:20",
  //   tags: "",
  //   likes: 12,
  //   rank: 3,
  //   ASAID: 64,
  //   previewURL: "https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3",
  //   fileURL: "",
  //   fileName: "",
  // },
  // {
  //   _id: 26,
  //   description:
  //     "ing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  //   name: "NASLOWSD",
  //   avatar: ImgSample16,
  //   isFractional: true,
  //   fractions: 3,
  //   creator: "",
  //   createdAT: "",
  //   creationRound: "1234567",
  //   owner: { name: "SYI", avatar: img3 },
  //   isAuction: true,
  //   isSale: false,
  //   isCollection: false,
  //   collectionType: "album",
  //   fileType: "aac",
  //   hasPreview: true,
  //   price: 8,
  //   initialPrice: 10,
  //   extraMetadata: {
  //     artist: "SY",
  //     album: "Ljjh",
  //     year: "2021",
  //     genre: "Jaz",
  //     date: "",
  //     comment: "",
  //   },
  //   metadataURL: "",
  //   assetURL: "",
  //   size: 12,
  //   length: "1:40",
  //   tags: "",
  //   likes: 2,
  //   rank: 3,
  //   ASAID: 32,
  //   previewURL: "https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3",
  //   fileURL: "",
  //   fileName: "",
  // },
  // {
  //   _id: 37,
  //   description: "incididunt ut labore et dolore magna",
  //   name: "WRbvx",
  //   avatar: ImgSample21,
  //   isFractional: false,
  //   fractions: 2,
  //   creator: "",
  //   createdAT: "",
  //   creationRound: "1234567",
  //   owner: { name: "Gds", avatar: img2 },
  //   isAuction: false,
  //   isSale: true,
  //   isCollection: true,
  //   collectionType: "album",
  //   fileType: "aac",
  //   hasPreview: true,
  //   price: 7,
  //   initialPrice: 10,
  //   extraMetadata: {
  //     artist: "Nkj",
  //     album: "DS",
  //     year: "2020",
  //     genre: "",
  //     date: "",
  //     comment: "",
  //   },
  //   metadataURL: "",
  //   assetURL: "",
  //   size: 3,
  //   length: "1:40",
  //   tags: "",
  //   likes: 54,
  //   rank: 2,
  //   ASAID: 42,
  //   previewURL: "https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3",
  //   fileURL: "",
  //   fileName: "",
  // },
  // {
  //   _id: 14,
  //   description: "empor incididunt ut labore et dolore magna aliqua.",
  //   name: "Fsg",
  //   avatar: ImgSample17,
  //   isFractional: true,
  //   fractions: 33,
  //   creator: "",
  //   createdAT: "",
  //   creationRound: "1234567",
  //   owner: { name: "SYI", avatar: img3 },
  //   isAuction: false,
  //   isSale: true,
  //   isCollection: true,
  //   collectionType: "album",
  //   fileType: "aac",
  //   hasPreview: true,
  //   price: 10,
  //   initialPrice: 12,
  //   extraMetadata: {
  //     artist: "SY",
  //     album: "Ljjh",
  //     year: "2021",
  //     genre: "Jaz",
  //     date: "",
  //     comment: "",
  //   },
  //   metadataURL: "",
  //   assetURL: "",
  //   size: 12,
  //   length: "1:40",
  //   tags: "",
  //   likes: 2,
  //   rank: 3,
  //   ASAID: 32,
  //   previewURL: "https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3",
  //   fileURL: "",
  //   fileName: "",
  // },
  // {
  //   _id: 72,
  //   description:
  //     "Lorem ipeiusmod tempor incididunt ut labore et dolore magna aliqua.",
  //   name: "DAGWSGFAA",
  //   avatar: ImgSample19,
  //   isFractional: true,
  //   fractions: 33,
  //   creator: "",
  //   createdAT: "",
  //   creationRound: "1234567",
  //   owner: { name: "Gds", avatar: img2 },
  //   isAuction: true,
  //   isSale: false,
  //   isCollection: true,
  //   collectionType: "album",
  //   fileType: "aac",
  //   hasPreview: true,
  //   price: 15,
  //   initialPrice: 12,
  //   extraMetadata: {
  //     artist: "SY",
  //     album: "Ljjh",
  //     year: "2021",
  //     genre: "Jaz",
  //     date: "",
  //     comment: "",
  //   },
  //   metadataURL: "",
  //   assetURL: "",
  //   size: 12,
  //   length: "1:40",
  //   tags: "",
  //   likes: 2,
  //   rank: 3,
  //   ASAID: 32,
  //   previewURL: "https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3",
  //   fileURL: "",
  //   fileName: "",
  // },
  // {
  //   _id: 92,
  //   description:
  //     "Loetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  //   name: "LORE",
  //   avatar: ImgSample18,
  //   isFractional: true,
  //   fractions: 23,
  //   creator: "",
  //   createdAT: "",
  //   creationRound: "1234567",
  //   owner: { name: "SYI", avatar: img1 },
  //   isAuction: true,
  //   isSale: false,
  //   isCollection: true,
  //   collectionType: "album",
  //   fileType: "aac",
  //   hasPreview: true,
  //   price: 10,
  //   initialPrice: 12,
  //   extraMetadata: {
  //     artist: "SY",
  //     album: "Ljjh",
  //     year: "2021",
  //     genre: "Jaz",
  //     date: "",
  //     comment: "",
  //   },
  //   metadataURL: "",
  //   assetURL: "",
  //   size: 12,
  //   length: "1:40",
  //   tags: "",
  //   likes: 2,
  //   rank: 3,
  //   ASAID: 32,
  //   previewURL: "https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3",
  //   fileURL: "",
  //   fileName: "",
  // },
];

class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMyAssetView: false,
      isAuctionViewExpanded: false,
      isSaleViewExpanded: false,
      isAssetViewExpanded: true,
      isAuctionSelected: false,
      isSaleSelected: false,
      isDark:true
    };
  }

  render() {
    const {
      isMyAssetView,
      isAuctionViewExpanded,
      isSaleViewExpanded,
      isAssetViewExpanded,
      isAuctionSelected,
      isSaleSelected,
      isDark
    } = this.state;
    const { classes } = this.props;

    return (
      <div className={isDark ? classes.mainDivDark : classes.mainDiv}>
        <Header
          isMyAssetView={isMyAssetView}
          isAuctionSelected={isAuctionSelected}
          isSaleSelected={isSaleSelected}
          setSaleSelected={(isSelected) =>
            this.setState({ isSaleSelected: isSelected })
          }
          setAuctionSelected={(isSelected) =>
            this.setState({ isAuctionSelected: isSelected })
          }
          setView={(isMyAssets) => this.setState({ isMyAssetView: isMyAssets })}
          isDark={isDark}
          setDarkMode={(isDarkMode) => {
            let bodyElem = document.body
            if(bodyElem){
              bodyElem.style.backgroundColor = !isDark ? '#000000' : '#ffffff'
            }
            this.setState({isDark: isDarkMode})}}
        />
        <Grid container direction="row" spacing={3} className={classes.grid}>
          <Grid item xs={12} sm={12} md={12}>
            <AuctionsView
              assets={
                isMyAssetView
                  ? myAssets.filter(function (asset) {
                      return asset.isAuction === true;
                    })
                  : allAssets.filter(function (asset) {
                      return asset.isAuction === true;
                    })
              }
              isMyAssetView={isMyAssetView}
              isAuctionViewExpanded={isAuctionViewExpanded}
              isSaleViewExpanded={isSaleViewExpanded}
              isAssetViewExpanded={isAssetViewExpanded}
              isAuctionSelected={isAuctionSelected}
              setAuctionSelected={(isSelected) =>
                this.setState({ isAuctionSelected: isSelected })
              }
              setExpandedView={(a, b, c) =>
                this.setState({
                  isAuctionViewExpanded: a,
                  isSaleViewExpanded: b,
                  isAssetViewExpanded: c,
                })
              }
              isDark={isDark}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <SalesView
              isMyAssetView={isMyAssetView}
              assets={
                isMyAssetView
                  ? myAssets.filter(function (asset) {
                      return asset.isSale === true;
                    })
                  : allAssets.filter(function (asset) {
                      return asset.isSale === true;
                    })
              }
              isAuctionViewExpanded={isAuctionViewExpanded}
              isSaleViewExpanded={isSaleViewExpanded}
              isAssetViewExpanded={isAssetViewExpanded}
              isSaleSelected={isSaleSelected}
              setSaleSelected={(isSelected) =>
                this.setState({ isSaleSelected: isSelected })
              }
              setExpandedView={(a, b, c) =>
                this.setState({
                  isAuctionViewExpanded: a,
                  isSaleViewExpanded: b,
                  isAssetViewExpanded: c,
                })
              }
              isDark={isDark}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <AssetsView
              isMyAssetView={isMyAssetView}
              isAuctionSelected={isAuctionSelected}
              isSaleSelected={isSaleSelected}
              assets={isMyAssetView ? myAssets : allAssets}
              isAuctionViewExpanded={isAuctionViewExpanded}
              isSaleViewExpanded={isSaleViewExpanded}
              isAssetViewExpanded={isAssetViewExpanded}
              setExpandedView={(a, b, c) =>
                this.setState({
                  isAuctionViewExpanded: a,
                  isSaleViewExpanded: b,
                  isAssetViewExpanded: c,
                })
              }
              isDark={isDark}
              setAuctionSelected={(isSelected) =>
                this.setState({ isAuctionSelected: isSelected })
              }
              setSaleSelected={(isSelected) =>
                this.setState({ isSaleSelected: isSelected })
              }
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}
HomePage.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  onLogoutClick: PropTypes.func.isRequired,
};
export default withStyles(styles)(HomePage);
