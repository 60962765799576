import React, { Component } from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import MobileStepper from "@material-ui/core/MobileStepper";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";
import KeyboardArrowLeft from "@material-ui/icons/ArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/ArrowRight";
import PlayCircleOutline from "@material-ui/icons/PlayCircleOutline";
import PauseCircleOutline from "@material-ui/icons/PauseCircleOutline";
import Close from "@material-ui/icons/Close";
import { withStyles } from "@material-ui/styles";
import StepperStepTwo from "./StepperStepTwo";
import StepperStepThree from "./StepperStepThree";
import StepperStepFour from "./StepperStepFour";
import StepperStepFive from "./StepperStepFive";

const styles = (theme) => ({
  dialogTitle: {
    fontSize: 26,
    fontWeight: "bold",
  },
  closeButton: {
    position: "absolute",
    right: "16px",
    top: "16px",
    color: "#ff3300",
  },
  box: {
    height: 255,
    textAlign: "center",
  },
  paper: {
    width: "85%",
    borderRadius: 10,
    "&::-webkit-scrollbar": {
      width: "5px",
      height: "5px",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 5px grey",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#726e6e",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#605c5c",
    },
    scrollbarColor: "#726e6e",
    scrollbarWidth: "thin",
  },
  playIcon: {
    color: "#d94804",
    fontSize: 45,
    "&:hover": {
      color: "#4feb24d1",
    },
  },
  submitBtn: {
    margin: 10,
    marginLeft: "77%",
    float: "right",
    backgroundColor: "#f5efed",
    color: "#d94804",
    borderRadius: 25,
    textTransform: "none",
    width: "20%",
    marginTop: 20,
    "&:hover": {
      backgroundColor: "#d94804",
      color: "#ffffff",
    },
  },
  stepperBtn: {
    "&:hover": {
      backgroundColor: "#d94804",
      color: "#ffffff",
    },
  },
  paperDark:{
    backgroundColor: '#222222',
    width: "85%",
    borderRadius: 10,
    "&::-webkit-scrollbar": {
      width: "5px",
      height: "5px",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 5px grey",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#726e6e",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#605c5c",
    },
    scrollbarColor: "#726e6e",
    scrollbarWidth: "thin",
  }
});
class NftStepper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPreviewPlay: false,
      activeStep: 0,
      name: "",
      description: "",
      avatar: "",
      isFractional: true,
      isCollection: false,
      fractions: 0,
      collectionType: "",
      isSale: true,
      isAuction: false,
      hasPreview: false,
      fileURL: "",
      price: 0,
      initialPrice: 0,
      tags: "",
      previewURL: "",
      extraMetadata: {
        artist: "",
        album: "",
        year: "2021",
        genre: "",
        date: "2021-03-24",
        comment: "",
      },
    };
    this.handleNext = this.handleNext.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleStepChange = this.handleStepChange.bind(this);
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
    this.submitNft = this.submitNft.bind(this);
    this.handleUploadFile = this.handleUploadFile.bind(this);
    this.handleTogglePreview = this.handleTogglePreview.bind(this);

  }

  handleNext(prevActiveStep) {
    this.setState({ activeStep: prevActiveStep + 1 });
  }

  handleBack(prevActiveStep) {
    this.setState({ activeStep: prevActiveStep - 1 });
  }

  handleStepChange(step) {
    this.setState({ activeStep: step });
  }

  handleCloseDialog() {
    this.props.handleClose(false);
  }

  handleUploadFile(event) {
    this.setState({ 
      fileURL: event.target.value,
      isPreviewPlay: ! this.state.isPreviewPlay
     });
  }

  handleTogglePreview(url) {
   
      if (this.state.isPreviewPlay) {
        window.audioElement.pause();
        window.audioElement.currentTime = 0;
        // this.setState({ fileURL: !this.state.fileURL, previewAudio: url });     
      } else {
        window.audioElement = new Audio(url);
        window.audioElement.play();
        // this.setState({ fileURL: !this.state.fileURL, previewAudio: url });
      }
    
  }

  submitNft() {}


  render() {
    const {
      isPreviewPlay,
      activeStep,
      name,
      description,
      avatar,
      isFractional,
      isCollection,
      collectionType,
      isSale,
      isAuction,
      hasPreview,
      fractions,
      fileURL,
      price,
      initialPrice,
      tags,
      previewURL,
      extraMetadata,
    } = this.state;
    const { isNftStepperOpen, classes, isDark } = this.props;

    const steps = [
      {
        label: "Step 1",
        content: (
         <>
          <Typography variant='body' style={{color: isDark && '#fff'}}>Upload your music file</Typography>
          <br />
            <Input
            id="audio-upload" 
            disableUnderline
            type="file"
            accept="audio/*"
            style={{
              padding: 10,
              background: "#f0570530",
              marginTop: "10%",
              borderRadius: 10,
            }}
            onChange={this.handleUploadFile}
          />
          {fileURL !== '' && <IconButton
                className={classes.iconButton}
                onClick={() =>
                  this.handleTogglePreview(fileURL)
                }
              >
                {isPreviewPlay ? (
                  <PauseCircleOutline className={classes.playIcon} />
                ) : (
                  <PlayCircleOutline
                    className={classes.playIcon}
                  />
                )}
              </IconButton>}
         </>
        ),
      },
      {
        label: "Step 2",
        content: (
          <StepperStepTwo
            setDescription={(desc) => this.setState({ description: desc })}
            setName={(title) => this.setState({ name: title })}
            setAvatar={(img) => this.setState({ avatar: img })}
            name={name}
            description={description}
            avatar={avatar}
            fileURL={fileURL}
            isDark={isDark}
            style={{ marginTop: "10%" }}
          />
        ),
      },
      {
        label: "Step 3",
        content: (
          <StepperStepThree
            isFractional={isFractional}
            isCollection={isCollection}
            collectionType={collectionType}
            isSale={isSale}
            isAuction={isAuction}
            hasPreview={hasPreview}
            fractions={fractions}
            fileURL={fileURL}
            previewURL={previewURL}
            setIsFractional={(is) => this.setState({ isFractional: is })}
            setIsCollection={(is) => this.setState({ isCollection: is })}
            setIsAuction={(is) => this.setState({ isAuction: is })}
            setIsSale={(is) => this.setState({ isSale: is })}
            setHasPreview={(is) => this.setState({ hasPreview: is })}
            setPreviewURL={(url) => this.setState({ previewURL: url })}
            setFractions={(qty) => this.setState({ fractions: qty })}
            setFileURL={(address) => this.setState({ fractions: address })}
            setCollectionType={(type) =>
              this.setState({ collectionType: type })
            }
            style={{ marginTop: "10%" }}
            isDark={isDark}
          />
        ),
      },
      {
        label: "Step 4",
        content: (
          <StepperStepFour
            artist={extraMetadata.artist}
            band={extraMetadata.band}
            album={extraMetadata.album}
            year={extraMetadata.year}
            date={extraMetadata.date}
            genre={extraMetadata.genre}
            setArtist={(name) =>
              this.setState({ extraMetadata: { artist: name } })
            }
            setBand={(name) => this.setState({ extraMetadata: { band: name } })}
            setAlbum={(name) =>
              this.setState({ extraMetadata: { album: name } })
            }
            setGenre={(name) =>
              this.setState({ extraMetadata: { genre: name } })
            }
            setDate={(date) => this.setState({ extraMetadata: { date: date } })}
            setYear={(year) => this.setState({ extraMetadata: { year: year } })}
            style={{ marginTop: "10%" }}
            isDark={isDark}
          />
        ),
      },
      {
        label: "Step 5",
        content: (
          <StepperStepFive
            price={price}
            initialPrice={initialPrice}
            tags={tags}
            isSale={isSale}
            isAuction={isAuction}
            setPrice={(price) => this.setState({ price: price })}
            setInitialPrice={(price) => this.setState({ initialPrice: price })}
            setTags={(tags) => this.setState({ tags: tags })}
            style={{ marginTop: "10%" }}
            isDark={isDark}
          />
        ),
      },
    ];
    const maxSteps = steps.length;

    return (
      <Dialog
        onClose={this.handleCloseDialog}
        open={isNftStepperOpen}
        scroll="paper"
        classes={{ paper: isDark ? classes.paperDark :  classes.paper }}
        price={price}
        initialPrice={initialPrice}
        tags={tags}
      >
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={this.handleCloseDialog}
        >
          <Close />
        </IconButton>
          
        <DialogTitle className={classes.dialogTitle} style={{ color: isDark && "#ffffff" }}>
          Mint New NFT
        </DialogTitle>

        <Paper
          square
          elevation={0}
          sx={{
            display: "flex",
            alignItems: "center",
            height: 50,
            pl: 2,
          }}
        ></Paper>
        <Box className={classes.box}>{steps[activeStep].content}</Box>
        {activeStep === 4 ? (
          <Button
            size="small"
            className={classes.submitBtn}
            onClick={this.submitNft}
          >
            Submit
          </Button>
        ) : null}
        <MobileStepper
          variant="text"
          steps={maxSteps}
          style={{ backgroundColor: isDark && "#222222", color: isDark && "#ffffff" }}
          position="static"
          activeStep={activeStep}
          nextButton={
            <Button
              size="small"
              onClick={() => this.handleNext(activeStep)}
              // disabled={fileURL === "" || activeStep === 4 ? true : false}
              disabled={activeStep === 4 ? true : false}

              className={classes.stepperBtn}
              style={{ color: isDark && "#ffffff" }}
            >
              Next
              <KeyboardArrowRight />
            </Button>
          }
          backButton={
            <Button
              size="small"
              onClick={() => this.handleBack(activeStep)}
              disabled={activeStep === 0}
              className={classes.stepperBtn}
              style={{ color: isDark && "#ffffff" }}
            >
              <KeyboardArrowLeft />
              Back
            </Button>
          }
        />
      </Dialog>
    );
  }
}
NftStepper.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  isNftStepperOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  isDark: PropTypes.bool.isRequired,
};
export default withStyles(styles)(NftStepper);
