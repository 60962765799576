import React, { Component } from "react";
import PropTypes from "prop-types";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import IconButton from "@material-ui/core/IconButton";
import algo from "../assets/images/algo-white.png";
import FavoriteBorderOutlined from "@material-ui/icons/FavoriteBorderOutlined";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const styles = (theme) => ({
  image: {
    width: "100%",
  },
  tileBar: {
    backgroundImage: "linear-gradient(to right, #2f2d2d99 10% ,#c8c6c624 )",
    textAlign: "left",
    opacity: 0,
    "&:hover": {
      opacity: 1,
    },
  },
  listItemRoot: {
    paddingTop: 0,
  },
  listItemAvatarRoot: {
    minWidth: 23,
    marginRight: 5,
  },
  primary: {
    fontSize: 14,
  },
  rootSubtitle: {
    height: 85,
  },
  titleWrap: {
    marginLeft: 8,
    marginBottom: 16,
  },
  gridItem: {
    minHeight: "200px",
    maxHeight: "200px",
    height: "200px",
    [theme.breakpoints.down("xs")]: {
      height: "auto",
      minHeight: "none",
      maxHeight: "none",
    },
  },
  card: {
    cursor: "pointer",
    maxWidth: 200,
    minHeight: "200px",
    maxHeight: "250px",
    width: "180px",
    backgroundColor: "#ffffff",
    borderRadius: 0,
    boxShadow: "none",
    borderRadius: 8,
    // border: '1px solid #c0c0c0',
    [theme.breakpoints.down("xs")]: {
      // height: 350,
      // width: 300,
    },
  },
  cardActionAreaRoot: {
    height: "200px",
  },

  buyBtn: {
    position: "absolute",
    bottom: "2%",
    right: "5%",
    display: "inline-block",
  },
  buyIcon: {
    color: "#ffffff",
    "&:hover": {
      color: "#fd9235fc",
    },
  },
  likesDiv: {
    position: "absolute",
    top: "5%",
    right: "4%",
    backgroundImage:'linear-gradient(to right, #9d2206a3, #44414199)',
    borderRadius: 24,
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    height: 26,
    width: 'auto',
    paddingLeft: '6px',
    paddingRight: '4px', 
  },
  icon:{
    color:'#ffffff'
  },
  priceDiv: {
    position: "absolute",
    top: "5%",
    left: "4%",
    backgroundImage:'linear-gradient(to left, #5757578c, #44414199)',
    borderRadius: 24,
    width: '27%',
    height: 26,
    paddingLeft: '6px',
    paddingRight: '4px',   
  },
  price: {
    fontSize: 20,
    display: "inline",
    color: "#ffffff",
  },
  algoImg: {
    width: 22,
    display: "inline",
  },
  cardTitle: {
    position: "absolute",
    top: "16%",
    left: "21%",
    width: "100%",
    textAlign: "left",
    color: "#ffffff",
    [theme.breakpoints.down("xs")]: {
      left: "21%",
    },
    [theme.breakpoints.down("lg")]: {
      left: "21%",
    },
    [theme.breakpoints.down("md")]: {
      left: "27%",
    },
    [theme.breakpoints.down("sm")]: {
      left: "25%",
    },
  },
  ownerAvatar: {
    position: "absolute",
    top: "20%",
    left: "5%",
    textAlign: "left",
  },
  ownerName: {
    position: "absolute",
    top: "57%",
    left: "21%",
    textAlign: "left",
    fontSize: 12,
    color: "#ffffff",
    [theme.breakpoints.down("xs")]: {
      left: "21%",
    },
    [theme.breakpoints.down("lg")]: {
      left: "21%",
    },
    [theme.breakpoints.down("md")]: {
      left: "27%",
    },
    [theme.breakpoints.down("sm")]: {
      left: "25%",
    },
  },
});

class CarouselView extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleBuyClick = this.handleBuyClick.bind(this);
  }
  handleBuyClick() {}

  render() {
    const { classes, asset, setSelectedAsset } = this.props;

    return (
      <Grid item className={classes.gridItem}>
        <Card className={classes.card}>
          <CardActionArea
            classes={{ root: classes.cardActionAreaRoot }}
            onClick={() => setSelectedAsset(asset, true)}
          >
            <CardMedia
              component="img"
              style={{ height: "100%" }}
              image={asset.avatar}
            />
            <div className={classes.priceDiv}>
              <Typography className={classes.price}>{asset.price}</Typography>
              <img src={algo} className={classes.algoImg} />
            </div>
            <div
              className={classes.likesDiv}
            >
              <FavoriteBorderOutlined className={classes.icon} />
              <span style={{color: '#ffffff'}}>{asset.likes}</span>
            </div>
            <GridListTileBar
              className={classes.tileBar}
              classes={{
                rootSubtitle: classes.rootSubtitle,
                titleWrap: classes.titleWrap,
              }}
              title={
                <>
                <Avatar
                          src={asset.owner.avatar}
                          className={classes.ownerAvatar}
                        />
                        <Typography
                          gutterBottom
                          variant="title"
                          component="title"
                          className={classes.cardTitle}
                        >
                          {asset.name.length > 7
                            ? asset.name.substring(0, 6) + "..."
                            : asset.name}
                        </Typography>
                        <br />
                        <br />
                        <Typography gutterBottom className={classes.ownerName}>
                        {`@ ${asset.owner.name}`}
                        </Typography>




                
                  {/* <ListItem
                    disableGutters
                    classes={{ root: classes.listItemRoot }}
                  >
                    <ListItemAvatar
                      classes={{
                        root: classes.listItemAvatarRoot,
                      }}
                    >
                      <Avatar src={asset.owner.avatar} />
                    </ListItemAvatar>
                    <ListItemText
                      classes={{ primary: classes.primary }}
                      primary={`@ ${asset.owner.name}`}
                    />
                  </ListItem> */}
                </>
              }
            />
          </CardActionArea>
        </Card>
      </Grid>
    );
  }
}

CarouselView.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  asset: PropTypes.objectOf(PropTypes.string).isRequired,
  setSelectedAsset: PropTypes.func.isRequired,
};
export default withStyles(styles)(CarouselView);
