import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import algo from "../assets/images/algo.png";
import algoWhite from "../assets/images/algo-white.png";
import PlayCircleOutline from "@material-ui/icons/PlayCircleOutline";
import PauseCircleOutline from "@material-ui/icons/PauseCircleOutline";
import ShoppingCart from "@material-ui/icons/ShoppingCartOutlined";
import StarBorderOutlined from "@material-ui/icons/StarBorderOutlined";
import FavoriteBorderOutlined from "@material-ui/icons/FavoriteBorderOutlined";
// import ReactWaves from "@dschoon/react-waves";
import {
  Paper,
  Grid,
  Typography,
  ListItem,
  ListItemText,
  IconButton,
  ListItemAvatar,
  Avatar,
  List,
  ListItemSecondaryAction,
  Button,
} from "@material-ui/core";

const styles = (theme) => ({
  coverImage: {
    width: 320,
    height: "auto",
    borderRadius: 5,
  },
  assetName: {
    color: "#2e2d2d",
  },
  grid: {
    padding: "2%",
  },
  badge: {
    backgroundColor: "#fce1e4",
    borderRadius: "40px",
    textAlign: "center",
    display: "inline-block",
    marginBottom: 10,
    marginRight: "7%",
  },
  badgeSpan: {
    color: "#434242",
    padding: "0px 11px",
    fontSize: "1em",
    lineHeight: 3,
  },
  margin: {
    height: theme.spacing(2),
  },
  backBtn: {
    margin: "5px",
  },
  price: {
    color: "#585555",
    fontWeight: "bold",
    display: "inline",
  },
  algoImg: {
    width: 50,
    display: "inline",
  },
  playIcon: {
    color: "#d94804",
    fontSize: 45,
    "&:hover": {
      color: "#4feb24d1",
    },
  },
  disabledPlayIcon: {
    color: "#a39f9f",
    fontSize: 45,
  },
  buyBtn: {
    position: "absolute",
    right: "13%",
    bottom: 0,
    display: "inline-block",
  },
  buyIcon: {
    color: "#434242",
    fontSize: 50,
  },
  listItemTextRoot: {
    marginLeft: "-11px",
  },
  algoBtn: {
    padding: "7px 28px",
    backgroundColor: "#f1f1f1",
    color: "#3d3c3c",
    marginTop: "10px",
    borderRadius:27,
    [theme.breakpoints.down("xl")]: {
      marginRight: "4%",
    },
    [theme.breakpoints.down("lg")]: {
      marginRight: "3%",
    },
    [theme.breakpoints.down("md")]: {
      marginRight: "4%",
    },
    [theme.breakpoints.down("sm")]: {
      marginRight: "3%",
    },
    [theme.breakpoints.down("xs")]: {
      marginRight: "2%",
    },
  },
  priceDiv:{
    position: 'relative',
    display: 'block',
    [theme.breakpoints.down('xs')]:{
      display: 'none'
    }
  },
  priceDivStyle:{
    position: 'relative',
    display: 'none',
    [theme.breakpoints.down('xs')]:{
      display: 'block'
    }
  },
  secondaryText:{
    color:'#ffffff'
  }
});

class SelectedAssetWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPreviewPlay: false,
    };
    this.handleTogglePreview = this.handleTogglePreview.bind(this);
    this.handleBuyClick = this.handleBuyClick.bind(this);
  }

  handleTogglePreview(url) {
    if(url.toLowerCase().indexOf('https://')>-1 || url.toLowerCase().indexOf('ipfs://')>-1){
      if (this.state.isPreviewPlay) {
        window.audioElement.pause();
        window.audioElement.currentTime = 0;
        this.setState({ isPreviewPlay: !this.state.isPreviewPlay, previewAudio: url });     
      } else {
        window.audioElement = new Audio(url);
        window.audioElement.play();
        this.setState({ isPreviewPlay: !this.state.isPreviewPlay, previewAudio: url });
      }
    }
  }

  handleBuyClick() {}

  render() {
    const { classes, selectedAsset, setIsSelectedAssetOpen,isDark } = this.props;
    const { isPreviewPlay } = this.state;

    return (
      <Paper elevation={4} style={{backgroundColor:isDark && '#222222', color: isDark && '#b2b1b1'}}>
        <IconButton
          className={classes.backBtn}
          onClick={() => setIsSelectedAssetOpen(false)}
        >
          <ArrowBackIos style={{color: isDark && '#ffffff'}} />
        </IconButton>
        <Grid
          container
          direction="row"
          className={classes.grid}
          justifyContent="center"
          alignItems="flex-start"
        >
          <Grid item container justify="center" xs={12} sm={12} md={6}>
            <div>
              <img src={selectedAsset.avatar} className={classes.coverImage} />
              <br />
              <Typography variant="h4" className={classes.assetName} style={{color: isDark && '#ffffff'}}>
                {selectedAsset.name}
              </Typography>

              <List>
                <ListItem>
                  <ListItemSecondaryAction>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                      }}
                    >
                      <StarBorderOutlined />
                      <span>{selectedAsset.rank}</span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                      }}
                    >
                      <FavoriteBorderOutlined />
                      <span>{selectedAsset.likes}</span>
                    </div>
                  </ListItemSecondaryAction>
                  <ListItemAvatar>
                    <Avatar src={selectedAsset.owner.avatar} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={`@ ${selectedAsset.owner.name}`}
                    classes={{ root: classes.listItemTextRoot }}
                  />
                </ListItem>
              </List>
              <Typography variant="body" style={{color: isDark && '#b2b1b1'}}>
                {selectedAsset.description}
              </Typography>
            </div>
          </Grid>
          <Grid container justify="center" item xs={12} sm={12} md={6}>
            <Grid item xs={4} sm={4} md={4} justify="flex-start">
              <List>
                <ListItem>
                  <ListItemText
                    primary="Artist"
                    secondary={selectedAsset.extraMetadata.artist}
                    classes={{secondary: isDark && classes.secondaryText}}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Album"
                    secondary={selectedAsset.extraMetadata.album}
                    classes={{secondary: isDark && classes.secondaryText}}

                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Band"
                    secondary={selectedAsset.extraMetadata.band}
                    classes={{secondary: isDark && classes.secondaryText}}

                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Year"
                    secondary={selectedAsset.extraMetadata.year}
                    classes={{secondary: isDark && classes.secondaryText}}

                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Genre"
                    secondary={selectedAsset.extraMetadata.genre}
                    classes={{secondary: isDark && classes.secondaryText}}

                  />
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={4} sm={4} md={4} justify="flex-start">
              <List>
                <ListItem>
                  <ListItemText primary="Size" secondary={selectedAsset.size} 
                    classes={{secondary: isDark && classes.secondaryText}}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Length"
                    secondary={selectedAsset.length}
                    classes={{secondary: isDark && classes.secondaryText}}

                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Creation Round"
                    secondary={selectedAsset.creationRound}
                    classes={{secondary: isDark && classes.secondaryText}}

                  />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Tags" secondary={selectedAsset.tags} 
                    classes={{secondary: isDark && classes.secondaryText}}

                  />
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={4} sm={4} md={4} justify="flex-start">

              <IconButton
                className={classes.iconButton}
                disabled={selectedAsset.hasPreview ? false : true}
                onClick={() =>
                  this.handleTogglePreview(selectedAsset.previewURL)
                }
              >
                {isPreviewPlay ? (
                  <PauseCircleOutline className={classes.playIcon} />
                ) : (
                  <PlayCircleOutline
                    className={
                      selectedAsset.hasPreview
                        ? classes.playIcon
                        : classes.disabledPlayIcon
                    }
                  />
                )}
              </IconButton>
              <br />
              {selectedAsset.isFractional && (
                <>
                  <div
                    className={classes.badge}
                    style={{ backgroundColor: "#fce1e4" }}
                  >
                    <span className={classes.badgeSpan}>
                      {`Fractional: ${selectedAsset.fractions}`}
                    </span>
                  </div>
                </>
              )}
              {selectedAsset.isSale && (
                <div
                  className={classes.badge}
                  style={{ backgroundColor: "#f6e6da" }}
                >
                  <span className={classes.badgeSpan}>Sale</span>
                </div>
              )}
              {selectedAsset.isAuction && (
                <div
                  className={classes.badge}
                  style={{ backgroundColor: "#e5f6da" }}
                >
                  <span className={classes.badgeSpan}>Auction</span>
                </div>
              )}
              <div
                className={classes.badge}
                style={{ backgroundColor: "#daeaf6" }}
              >
                <span className={classes.badgeSpan}>
                  {selectedAsset.collectionType === "album"
                    ? "Album"
                    : selectedAsset.collectionType === "playlist"
                    ? "Playlist"
                    : "Folder"}
                </span>
              </div>

              <div
                className={classes.badge}
                style={{ backgroundColor: "#e8dff5" }}
              >
                <span className={classes.badgeSpan}>
                  {selectedAsset.filType === "mp4"
                    ? "mp4"
                    : selectedAsset.collectionType === "aac"
                    ? "aac"
                    : "wave"}
                </span>
              </div>
              <br />
              <br />
              <br />
              <div className={classes.priceDiv}>
                <Typography variant="h3" className={classes.price} style={{color: isDark && '#FFF'}}>
                  {selectedAsset.price}
                </Typography>
                <img src={isDark ? algoWhite : algo} className={classes.algoImg} />
                <IconButton
                  className={classes.buyBtn}
                  onClick={this.handleBuyClick}
                >
                  <ShoppingCart className={classes.buyIcon} style={{color: isDark && '#FFF'}} />
                </IconButton>
              </div>
            </Grid>
            <Grid container alignItems="flex-end">
            <Grid item xs={12} sm={12} md={12}>
            <div className={classes.priceDivStyle}>
                <Typography variant="h3" className={classes.price} style={{color: isDark && '#FFF'}}>
                  {selectedAsset.price}
                </Typography>
                <img src={isDark ? algoWhite : algo} className={classes.algoImg} />
                <IconButton
                  className={classes.buyBtn}
                  onClick={this.handleBuyClick}
                >
                  <ShoppingCart className={classes.buyIcon} style={{color: isDark && '#FFF'}} />
                </IconButton>
              </div>
            </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Button className={classes.algoBtn} style={{color: isDark && '#cdcdcd',backgroundColor:  isDark && 'rgb(24 24 24)'}}>
                  Algo Explorer Transaction
                </Button>
                <Button className={classes.algoBtn} style={{color: isDark && '#cdcdcd',backgroundColor:  isDark && 'rgb(24 24 24)'}}>Algo Explorer Asset</Button>
                <Button className={classes.algoBtn} style={{color: isDark && '#cdcdcd',backgroundColor:  isDark && 'rgb(24 24 24)'}}>IPFS Asset</Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}
SelectedAssetWidget.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  setisTileView: PropTypes.func.isRequired,
  setIsSelectedAssetOpen: PropTypes.func.isRequired,
  assets: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string,
      avatar: PropTypes.string,
      isFractional: PropTypes.bool,
      fractions: PropTypes.number,
      creator: PropTypes.string,
      createdOn: PropTypes.string,
      metadata: PropTypes.array,
      isAuction: PropTypes.bool,
      isSale: PropTypes.bool,
      hasPreview: PropTypes.bool,
      price: PropTypes.string,
      owner: PropTypes.object,
      size: PropTypes.string,
      length: PropTypes.string,
    })
  ).isRequired,
  selectedAsset: PropTypes.object.isRequired,
  isDark: PropTypes.bool.isRequired,
};
export default withStyles(styles)(SelectedAssetWidget);
