import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Avatar from "@material-ui/core/Avatar";
import Paper from "@material-ui/core/Paper";
import Check from "@material-ui/icons/CheckCircleOutline";
import Close from "@material-ui/icons/HighlightOff";

const styles = (theme) => ({
  paper: {
    overflowX: "auto",
    marginRight: "auto",
    marginLeft: "auto",
    padding: "10px",
    margin: "10px",
    marginTop: "27px",
    "&::-webkit-scrollbar": {
      width: "5px",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 5px grey",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#808080",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#808070",
    },
    scrollbarColor: "#808080",
    scrollbarWidth: "thin",
  },
  table: {
    margin: 40,
  },
  tableRoot: {
    [theme.breakpoints.down("xl")]: {
      width: "90%",
    },
    [theme.breakpoints.down("lg")]: {
      width: "92%",
    },
    [theme.breakpoints.down("md")]: {
      width: "94%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "95%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "96%",
    },
  },
  tableHeader: {
    paddingRight: 4,
    paddingLeft: 5,
    fontWeight: "bold",
  },
  closeIcon:{
    color:'#fce1e4'
  },
  checkIcon:{
    color:'#ddedea '
  },
  avatar: {
    width: 60,
    height: 60,
    // marginLeft:'35%',
    marginBottom: "10%",
  },
  selectTableCell: {
    width: 60,
  },
  tableCell: {
    width: 130,
    height: 40,
    paddingRight: 4,
    paddingLeft: 5,
  },
});

class TableVidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedAsset: {},
      selectedAssetId: "",
    };
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
  }

  handleCloseDialog() {
    const { setAssetModalOpen } = this.props;
    this.setState({
      isEditMode: false,
    });
    setAssetModalOpen(false);
  }

  render() {
    const { classes, assets, setSelectedAsset,isDark} = this.props;
    const { selectedAsset } = this.state;

    return (
      <>
        <Paper className={classes.paper} style={{backgroundColor: isDark && '#222222', }}>
          <Table
            className={classes.table}
            classes={{ root: classes.tableRoot }}
            size="small"
          >
            <TableHead>
              <TableRow>
                <TableCell align="left" className={classes.tableHeader} style={{color: isDark && '#b2b1b1'}} >
                  Id
                </TableCell>
                <TableCell align="left" className={classes.tableHeader} style={{color: isDark && '#b2b1b1'}}>
                  NFT
                </TableCell>
                <TableCell align="left" className={classes.tableHeader} style={{color: isDark && '#b2b1b1'}}>
                  Name
                </TableCell>
                <TableCell align="left" className={classes.tableHeader} style={{color: isDark && '#b2b1b1'}}>
                  Description
                </TableCell>
                <TableCell align="left" className={classes.tableHeader} style={{color: isDark && '#b2b1b1'}}>
                  Fractional
                </TableCell>
                <TableCell align="left" className={classes.tableHeader} style={{color: isDark && '#b2b1b1'}}>
                  Pices
                </TableCell>
                <TableCell align="left" className={classes.tableHeader} style={{color: isDark && '#b2b1b1'}}>
                  Collection
                </TableCell>
                <TableCell align="left" className={classes.tableHeader} style={{color: isDark && '#b2b1b1'}}>
                  Collection type
                </TableCell>
                <TableCell align="left" className={classes.tableHeader} style={{color: isDark && '#b2b1b1'}}>
                  File type
                </TableCell>
                <TableCell align="left" className={classes.tableHeader} style={{color: isDark && '#b2b1b1'}}>
                  Creator
                </TableCell>
                <TableCell align="left" className={classes.tableHeader} style={{color: isDark && '#b2b1b1'}}>
                  Created on
                </TableCell>
                <TableCell align="left" className={classes.tableHeader} style={{color: isDark && '#b2b1b1'}}>
                  Auction
                </TableCell>
                <TableCell align="left" className={classes.tableHeader} style={{color: isDark && '#b2b1b1'}}>
                  Sale
                </TableCell>
                <TableCell align="left" className={classes.tableHeader} style={{color: isDark && '#b2b1b1'}}>
                  Preview
                </TableCell>
                <TableCell align="left" className={classes.tableHeader} style={{color: isDark && '#b2b1b1'}}>
                  Price
                </TableCell>
                <TableCell align="left" className={classes.tableHeader} style={{color: isDark && '#b2b1b1'}}>
                  Owner
                </TableCell>
                <TableCell align="left" className={classes.tableHeader} style={{color: isDark && '#b2b1b1'}}>
                  Size
                </TableCell>
                <TableCell align="left" className={classes.tableHeader} style={{color: isDark && '#b2b1b1'}}>
                  Length
                </TableCell>
                <TableCell align="left" className={classes.tableHeader} style={{color: isDark && '#b2b1b1'}}>
                  
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {assets &&
                assets.map((asset) => (
                  <TableRow key={asset._id}> 
                  <TableCell align="left" className={classes.tableCell} style={{color: isDark && '#ffffff'}}>
                      {asset._id}
                    </TableCell>                   
                    <TableCell align="left" className={classes.tableCell} style={{color: isDark && '#ffffff'}}>
                      <Avatar src={asset.avatar} />
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell} style={{color: isDark && '#ffffff'}}>
                      {asset.name}
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell} style={{color: isDark && '#ffffff'}}>
                      {asset.description}
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell} style={{color: isDark && '#ffffff'}}>
                      {asset.isFractional === true ? <Check className={classes.checkIcon} /> : <Close className={classes.closeIcon} />}
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell} style={{color: isDark && '#ffffff'}}>
                      {asset.fractions}
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell} style={{color: isDark && '#ffffff'}}>
                      {asset.isCollection === true ? <Check className={classes.checkIcon} /> : <Close className={classes.closeIcon} />}
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell} style={{color: isDark && '#ffffff'}}>
                      {asset.collectionType}
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell} style={{color: isDark && '#ffffff'}}>
                      {asset.fileType}
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell} style={{color: isDark && '#ffffff'}}>
                      {asset.creator}
                    </TableCell>

                    <TableCell align="left" className={classes.tableCell} style={{color: isDark && '#ffffff'}}>
                      {asset.createdOn}
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell} style={{color: isDark && '#ffffff'}}>
                      {asset.isAuction === true ? <Check className={classes.checkIcon} /> : <Close className={classes.closeIcon} />}
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell} style={{color: isDark && '#ffffff'}}>
                      {asset.isSale === true ? <Check className={classes.checkIcon} /> : <Close className={classes.closeIcon} />}
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell} style={{color: isDark && '#ffffff'}}>
                      {asset.hasPreview === true ? <Check className={classes.checkIcon} /> : <Close className={classes.closeIcon} />}
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell} style={{color: isDark && '#ffffff'}}>
                      {asset.price}
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell} style={{color: isDark && '#ffffff'}}>
                      {asset.owner.name}
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell} style={{color: isDark && '#ffffff'}}>
                      {asset.size}
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell} style={{color: isDark && '#ffffff'}}>
                      {asset.length}
                    </TableCell>


                    <TableCell className={classes.selectTableCell} onClick={()=> setSelectedAsset(asset,true)}>
                      <Button style={{color: isDark && '#ffffff'}}>Open</Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Paper>
      </>
    );
  }
}
TableVidget.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  setAssetModalOpen: PropTypes.func.isRequired,
  assets: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string,
      avatar: PropTypes.string,
      isFractional: PropTypes.bool,
      fractions: PropTypes.string,
      creator: PropTypes.string,
      createdOn: PropTypes.string,
      metadata: PropTypes.array,
      isAuction: PropTypes.bool,
      isSale: PropTypes.bool,
      hasPreview: PropTypes.bool,
      price: PropTypes.string,
      owner: PropTypes.objectOf(PropTypes.string).isRequired,
      size: PropTypes.string,
      isCollection: PropTypes.bool.isRequired,
      collectionType: PropTypes.string.isRequired,
      fileType: PropTypes.string.isRequired,
    })
  ).isRequired,
  setSelectedAsset: PropTypes.func.isRequired,
  isDark: PropTypes.bool.isRequired,

};
export default withStyles(styles)(TableVidget);
