import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  FormControl,
  FormControlLabel,
  Select,
  MenuItem,
  Grid,
  InputLabel,
  select,
  Input,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Add from "@material-ui/icons/Add";
import Delete from "@material-ui/icons/Delete";
import algo from "../assets/images/algo.png";
import { withStyles } from "@material-ui/styles";
import ChipInput from "./CheapInput";

const styles = (theme) => ({
  textField: {
    width: "80%",
  },
  textFieldRootDark: {
    color: "#fff",
  },
  textFieldRoot: {
    color: "#000",
  },
  labelDark: {
    color: "#9f9d9d",
  },
  label: {
    color: "#000",
  },
  underlineDark: {
    borderBottom: "1px solid #ffffff",
    "&:before": {
      borderBottom: "1px solid #fff",
    },
    "&:after": {
      borderBottom: "2px solid #fff",
    },
  },
  underline: {
    borderBottom: "1px solid #000000",
  },
});

class StepperStepFour extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.handleArtistChange = this.handleArtistChange.bind(this);
    this.handleAlbumChange = this.handleAlbumChange.bind(this);
    this.handleBandChange = this.handleBandChange.bind(this);
    this.handleYearChange = this.handleYearChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleGenreChange = this.handleGenreChange.bind(this);
  }
  handleArtistChange(event) {
    const name = event.target.value;
    this.props.setArtist(name);
  }
  handleAlbumChange(event) {
    const name = event.target.value;
    this.props.setAlbum(name);
  }
  handleBandChange(event) {
    const name = event.target.value;
    this.props.setBand(name);
  }
  handleYearChange(event) {
    const year = event.target.value;
    this.props.setYear(year);
  }
  handleDateChange(event) {
    const date = event.target.value;
    this.props.setAlbum(date);
  }
  handleGenreChange(event) {
    const genre = event.target.value;
    this.props.setAlbum(genre);
  }

  render() {
    const {} = this.state;
    const { classes, band, album, year, date, genre, artist, isDark } =
      this.props;
    const arrayOfYears = () => {
      var max = new Date().getFullYear();
      var min = max - 50;
      var years = [];

      for (var i = max; i >= min; i--) {
        years.push(i);
      }
      return years;
    };
    const arrayOfMusicGenre = [
      "Rock",
      "Hip hop",
      "Jazz",
      "Pop",
      "Folk",
      "Blues",
      "Heavy metal",
      "Rhythm and blues",
      "Country",
      "Punk rock",
      "Classical",
      "Soul",
      "Electrponic",
      "Popular",
      "Electronic dance",
      "Funk",
      "Trance",
      "House",
      "Reggae",
      "Alternative rock",
      "Techno",
      "Dance",
      "Ambient",
      "Disco",
      "Opera",
      "Gospel",
      "Breakbeat",
      "Industrial",
      "Psychedelic rock",
      "Swing",
      "Baroque",
      "Hardcore",
      "Minimal",
      "Instrumental",
      "Indie rock",
      "Contemporary R&B",
      "Experimental",
      "Blues rock",
      "Hardcore punk",
      "Dubstep",
      "American folk",
      "Jazz fusion",
      "Psychedelic",
      "Romantic",
      "Big band",
      "New wave",
      "Country rock",
      "Grunge",
      "New-age",
      "Reggaeton",
      "Western",
    ];

    return (
      <Grid container justifyContent="flex-start" alignItems="flex-start">
        <Grid item xs={6} sm={6} md={6}>
          <FormControl style={{ width: "80%", marginTop: "5%" }}>
            <InputLabel
              htmlFor="artist-name"
              style={{ color: isDark ? "#fff" : "#000" }}
            >
              Artist
            </InputLabel>
            <Input
              id="artist-name"
              type="text"
              style={{ color: isDark ? "#fff" : "#000" }}
              className={classes.textField}
              defaultValue={artist}
              onChange={this.handleArtistChange}
              classes={{
                underline: isDark ? classes.underlineDark : classes.underline,
              }}
            />
          </FormControl>

          <FormControl style={{ width: "80%", marginTop: "5%" }}>
            <InputLabel
              htmlFor="album-name"
              style={{ color: isDark ? "#fff" : "#000" }}
            >
              Album
            </InputLabel>
            <Input
              id="album-name"
              type="text"
              style={{ color: isDark ? "#fff" : "#000" }}
              className={classes.textField}
              defaultValue={album}
              onChange={this.handleAlbumChange}
              classes={{
                underline: isDark ? classes.underlineDark : classes.underline,
              }}
            />
          </FormControl>
          <FormControl style={{ width: "80%", marginTop: "5%" }}>
            <InputLabel
              htmlFor="band-name"
              style={{ color: isDark ? "#fff" : "#000" }}
            >
              Band
            </InputLabel>
            <Input
              id="band-name"
              type="text"
              style={{ color: isDark ? "#fff" : "#000" }}
              className={classes.textField}
              defaultValue={band}
              onChange={this.handleBandChange}
              classes={{
                underline: isDark ? classes.underlineDark : classes.underline,
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={6} sm={6} md={6}>
          <FormControl style={{ width: "80%", marginTop: "5%" }}>
            <InputLabel
              htmlFor="publication-year"
              style={{ color: isDark ? "#fff" : "#000" }}
            >
              Publication year
            </InputLabel>
            <Select
              id="publication-year"
              type="select"
              style={{ color: isDark ? "#fff" : "#000" }}
              className={classes.textField}
              defaultValue={year}
              onChange={this.handleYearChange}
              classes={{
                underline: isDark ? classes.underlineDark : classes.underline,
              }}
              input={
                <Input
                  classes={{
                    underline: isDark
                      ? classes.underlineDark
                      : classes.underline,
                  }}
                  name="year"
                  id="publication-year"
                />
              }
            >
              {arrayOfYears().map((year) => (
                <MenuItem value={year}>{year}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl style={{ width: "80%", marginTop: "5%" }}>
            <InputLabel
              htmlFor="album-date"
              style={{ color: isDark ? "#fff" : "#000" }}
            >
              Album date
            </InputLabel>
            <Input
              id="album-date"
              type="date"
              style={{ color: isDark ? "#fff" : "#000" }}
              className={classes.textField}
              defaultValue={date}
              onChange={this.handleDateChange}
              classes={{
                underline: isDark ? classes.underlineDark : classes.underline,
              }}
            />
          </FormControl>

          <FormControl style={{ width: "80%", marginTop: "5%" }}>
            <InputLabel
              htmlFor="genre"
              style={{ color: isDark ? "#fff" : "#000" }}
            >
              Genre
            </InputLabel>
            <Select
              id="genre"
              type="select"
              style={{ color: isDark ? "#fff" : "#000" }}
              className={classes.textField}
              defaultValue={year}
              onChange={this.handleGenreChange}
              classes={{
                underline: isDark ? classes.underlineDark : classes.underline,
              }}
              input={
                <Input
                  classes={{
                    underline: isDark
                      ? classes.underlineDark
                      : classes.underline,
                  }}
                  name="genre"
                  id="genre"
                />
              }
            >
              {arrayOfMusicGenre.map((genre) => (
                <MenuItem value={genre}>{genre}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    );
  }
}
StepperStepFour.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  band: PropTypes.string.isRequired,
  artist: PropTypes.string.isRequired,
  album: PropTypes.string.isRequired,
  year: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  genre: PropTypes.string.isRequired,
  setArtist: PropTypes.func.isRequired,
  setBand: PropTypes.func.isRequired,
  setAlbum: PropTypes.func.isRequired,
  setGenre: PropTypes.func.isRequired,
  setDate: PropTypes.func.isRequired,
  setYear: PropTypes.func.isRequired,
  isDark: PropTypes.bool.isRequired,
};
export default withStyles(styles)(StepperStepFour);
