import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Apps from "@material-ui/icons/Apps";
import Add from "@material-ui/icons/Add";
import List from "@material-ui/icons/List";
import Fab from "@material-ui/core/Fab";
import Button from "@material-ui/core/Button";
import ViewCarousel from "@material-ui/icons/ViewCarousel";
import { IconButton, Paper, Typography } from "@material-ui/core";
import TileWidget from "./TileWidget";
import TableWidget from "./TableWidget";
import CarouselWidget from "./CarouselWidget";
import NftStepperDialog from "./NftStepperDialog";
import SelectedAssetWidget from "./SelectedAssetWidget";
import backgroundImage from "../assets/images/background-image3.png";


const styles = (theme) => ({
  root: theme.mixins.gutters({
    padding: theme.spacing(1),
    paddingBottom: 60,
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  }),
  headerBtns: {
    float: "right",
    display: "inline",
    [theme.breakpoints.down("xs")]: {
      marginRight: "-13px",
      textAlign: "right",
    },
  },
  fabBtn: {
    backgroundColor: "#eeeeee",
    color: "#d94804",
    opacity: 1,
    "&:hover": {
      backgroundColor: "#fc7c28",
      color: "#ffffff",
    },
  },
  activeBtn: {
    borderBottom: "2px solid #d747045e",
    borderRadius: 0,
  },
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "space-between",
  },
  title: {
    color: "#967258",
    fontSize: 20,
    height:'100%',
    display: "inline",
    float: "left",
    borderTopRightRadius: '14px',
    backgroundImage: 'linear-gradient(296deg, #f19e774a, #f4f4f400)',
    paddingRight: '8px',
    paddingLeft: '7px',
    
  },
  avatar: {
    width: 60,
    height: 60,
    // marginLeft:'35%',
    marginBottom: "10%",
  },
  nftBtn: {
    color: "#ffffff",
    backgroundColor: "#d94804",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
    "&:hover": {
      color: "#d94804",
      backgroundColor: "#d747045e",
    },
  },
  miniNftBtn: {
    color: "#ffffff",
    backgroundColor: "#d94804",
    height: "35px",
    fontWeight: "bold",
    borderRadius: "18px",
    padding: 18,
    display: "none",
    marginTop: 5,
    marginLeft: "27%",
    "&:hover": {
      color: "#d94804",
      backgroundColor: "#d747045e",
    },
    [theme.breakpoints.down("xs")]: {
      display: "flex",
    },
  },
});

class AssetView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTileView: true,
      isTableView: false,
      isCarouselView: false,
      isNftStepperOpen: false,
      isSelectedAssetOpen: false,
      selectedAsset: {},
    };
    this.handleClickTableView = this.handleClickTableView.bind(this);
    this.handleClickTileView = this.handleClickTileView.bind(this);
    this.handleClickCarouselView = this.handleClickCarouselView.bind(this);
    this.handleClickCreateBtn = this.handleClickCreateBtn.bind(this);
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
  }
  componentDidUpdate(prevProps) {
    if (
      this.props.isAuctionViewExpanded !== prevProps.isAuctionViewExpanded ||
      this.props.isSaleViewExpanded !== prevProps.isSaleViewExpanded ||
      this.props.isSaleSelected !== prevProps.isSaleSelected ||
      this.props.isAuctionSelected !== prevProps.isAuctionSelected ||
      this.props.isMyAssetView !== prevProps.isMyAssetView
    ) {
      this.setState({
        isTileView:
          this.props.isAuctionViewExpanded === true ||
          this.props.isSaleViewExpanded === true
            ? false
            : true,
        isTableView: false,
        isCarouselView:
          this.props.isAuctionViewExpanded === true ||
          this.props.isSaleViewExpanded === true
            ? true
            : false,
      });
      if(this.props.isAuctionSelected === true || this.props.isSaleSelected === true){
        this.setState({
          isTileView: false,
          isTableView: false,
          isCarouselView: true,
        })
      }
      if(this.props.isMyAssetView !== prevProps.isMyAssetView){
        this.setState({
          isTileView: true,
          isTableView: false,
          isCarouselView: false,
          isSelectedAssetOpen: false
        })
      }
    }
  }

  handleClickTableView() {
    this.setState({
      isTableView: true,
      isTileView: false,
      isCarouselView: false,
    });
    this.props.setExpandedView(false, false, true);
    this.props.setSaleSelected(false)
    this.props.setAuctionSelected(false)

  }

  handleClickTileView() {
    this.setState({
      isTileView: true,
      isTableView: false,
      isCarouselView: false,
    });
    this.props.setExpandedView(false, false, true);
    this.props.setSaleSelected(false)
    this.props.setAuctionSelected(false)
  }

  handleClickCarouselView() {
    this.setState({
      isTableView: false,
      isTileView: false,
      isCarouselView: true,
    });
    this.props.setSaleSelected(false)
    this.props.setAuctionSelected(false)
  }

  handleClickCreateBtn() {
    this.setState({
      isNftStepperOpen: true,
    });
  }

  handleCloseDialog() {
    this.setState({
      isNftStepperOpen: false,
    });
  }

  render() {
    const { classes, createAsset, assets, isMyAssetView, isDark } = this.props;
    const {
      isTileView,
      isTableView,
      isCarouselView,
      isNftStepperOpen,
      isSelectedAssetOpen,
      selectedAsset,
    } = this.state;

    return (
      <>
        <NftStepperDialog
          isNftStepperOpen={isNftStepperOpen}
          handleClose={(isOpen) => this.setState({ isNftStepperOpen: isOpen })}
          isDark={isDark}
        />
        {isSelectedAssetOpen ? (
          <SelectedAssetWidget
            selectedAsset={selectedAsset}
            setIsSelectedAssetOpen={(isOpen) =>
              this.setState({ isSelectedAssetOpen: isOpen })
            }
            isDark={isDark}
          />
        ) : (
          <Paper className={classes.root} elevation={4} style={{backgroundColor: isDark ? '#222222' : '#ffffff'}}>
            <div className={classes.container}>
              <Typography type="title" className={classes.title} style={{color: isDark && 'rgb(217 213 213)'}}>
                {isMyAssetView ? "My NFTs" : "All NFTs"}
              </Typography>
              {isMyAssetView && <IconButton
               className={classes.nftBtn}
                onClick={this.handleClickCreateBtn}
                >
                  <Add style={{color: isDark && '#ffffff'}} />
                </IconButton>}
              <div className={classes.headerBtns}>
                <Button
                  onClick={this.handleClickTileView}
                  className={isTileView ? classes.activeBtn : null}
                >
                  <Apps style={{color: isDark && '#ffffff'}} />
                </Button>
                <Button
                  onClick={this.handleClickTableView}
                  className={isTableView ? classes.activeBtn : null}
                >
                  <List style={{color: isDark && '#ffffff'}} />
                </Button>
                <Button
                  onClick={this.handleClickCarouselView}
                  className={isCarouselView ? classes.activeBtn : null}
                >
                  <ViewCarousel style={{color: isDark && '#ffffff'}} />
                </Button>
              </div>
            </div>
            <Button
              className={classes.miniNftBtn}
              size="small"
              onClick={this.handleClickCreateBtn}
            >
              Create new NFT
            </Button>

            {isTileView && (
              <TileWidget
                isNftStepperOpen={isNftStepperOpen}
                setAssetModalOpen={(isOpen) =>
                  this.setState({ isNftStepperOpen: isOpen })
                }
                setIsTileView={(isTile) =>
                  this.setState({ isTileView: isTile })
                }
                setSelectedAsset={(asset, isOpen) =>
                  this.setState({
                    selectedAsset: asset,
                    isSelectedAssetOpen: isOpen,
                  })
                }
                assets={assets}
                isSelectedAssetOpen={isSelectedAssetOpen}
                isDark={isDark}
              />
            )}
            {isTableView && (
              <TableWidget
                assets={assets}
                setSelectedAsset={(asset, isOpen) =>
                  this.setState({
                    selectedAsset: asset,
                    isSelectedAssetOpen: isOpen,
                  })
                }
                isDark={isDark}
              />
            )}
            {isCarouselView && <CarouselWidget assets={assets} />}
          </Paper>
        )}
      </>
    );
  }
}
AssetView.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  assets: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string,
      avatar: PropTypes.string,
      isFractional: PropTypes.bool,
      fractions: PropTypes.string,
      creator: PropTypes.string,
      createdOn: PropTypes.string,
      metadata: PropTypes.array,
      isAuction: PropTypes.bool,
      isSale: PropTypes.bool,
      hasPreview: PropTypes.bool,
      price: PropTypes.string,
      owner: PropTypes.string,
      size: PropTypes.string,
      length: PropTypes.string,
    })
  ).isRequired,
  createAsset: PropTypes.func.isRequired,
  isMyAssetView: PropTypes.bool.isRequired,
  isAuctionViewExpanded: PropTypes.bool.isRequired,
  isSaleViewExpanded: PropTypes.bool.isRequired,
  isAssetViewExpanded: PropTypes.bool.isRequired,
  isAssetViewExpanded: PropTypes.bool.isRequired,
  isAuctionSelected: PropTypes.bool.isRequired,
  isSaleSelected: PropTypes.bool.isRequired,
  setSaleSelected: PropTypes.func.isRequired,
  isDark: PropTypes.bool.isRequired,
};
export default withStyles(styles)(AssetView);
