import React, { Component } from "react";
import PropTypes from "prop-types";
import { Typography, Select, Grid, IconButton, 
  TextField,FormControl, InputLabel,Input } from "@material-ui/core";

import Add from "@material-ui/icons/Add";
import Delete from "@material-ui/icons/Delete";
import Person from "@material-ui/icons/Person";
import { withStyles } from "@material-ui/styles";
import ImageUploading from "react-images-uploading";
import logo from "../assets/images/logo.png";

const styles = (theme) => ({
  textField: {
    width: "70%",
  },
  image: {
    marginLeft: "20px",
    width: 150,
    height: 150,
  },
  icon: {
    marginLeft: "27%",
    backgroundColor: "#fc7c2791",
  },
  addIcon: {
    backgroundColor: "#fc7c2791",
    marginLeft: "32%",
    marginTop: "10px",
  },
  imageUploadDiv: {
    width: 150,
    height: 150,
    marginLeft: "15%",
  },
  textFieldRootDark: {
    color: "#fff",
  },
  textFieldRoot: {
    color: "#000",
  },
  labelDark: {
    color: "#9f9d9d",
  },
  label: {
    color: "#000",
  },
  underlineDark: {
    borderBottom: "1px solid #ffffff",
    "&:before": {
      borderBottom: "1px solid #fff",
    },
    "&:after": {
      borderBottom: "2px solid #fff",
    },
  },
  underline: {
    borderBottom: "1px solid #000000",
  },
});

class StepperStepTwo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [],
    };
    this.handleTitleChange = this.handleTitleChange.bind(this);
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    this.handleAvatarChange = this.handleAvatarChange.bind(this);
  }
  handleTitleChange(event) {
    this.props.setName(event.target.value);
  }

  handleDescriptionChange(event) {
    const desc = event.target.value;
    this.props.setDescription(desc);
  }

  handleAvatarChange(imageList, addUpdateIndex) {
    if (imageList[0]) {
      if (imageList[0].data_url) {
        this.props.setAvatar(imageList[0].data_url);
      } else {
        this.props.setAvatar("");
        this.setState({
          images: [],
        });
      }
    }
  }

  render() {
    const {} = this.state;
    const { classes, name, description, avatar, fileURL, isDark } = this.props;

    return (
      <Grid container>
        <Grid item xs={3} sm={3} md={3} style={{ marginTop: "1%" }}>
          <ImageUploading
            value={avatar}
            onChange={this.handleAvatarChange}
            maxNumber={1}
            dataURLKey="data_url"
          >
            {({
              imageList,
              onImageUpload,
              onImageRemove,
              isDragging,
              dragProps,
            }) => (
              <>
                {avatar === "" || avatar == null ? (
                  <>
                    <img src={logo} className={classes.imageUploadDiv} />
                    <div {...dragProps}></div>
                    <IconButton
                      aria-label="Add image"
                      component="span"
                      className={classes.addIcon}
                      onClick={onImageUpload}
                    >
                      <Add />
                    </IconButton>
                  </>
                ) : (
                  <>
                    <img
                      src={imageList}
                      alt=""
                      className={classes.image}
                      onClick={onImageUpload}
                    />
                    <IconButton
                      aria-label="Remove image"
                      component="span"
                      className={classes.icon}
                      onClick={() => onImageRemove(0)}
                    >
                      <Delete />
                    </IconButton>
                  </>
                )}
              </>
            )}
          </ImageUploading>
        </Grid>
        <Grid item xs={9} sm={9} md={9}>
          
          <FormControl style={{ width: "70%" }}>
            <InputLabel
              htmlFor="title"
              style={{ color: isDark ? "#fff" : "#000" }}
            >
              Title
            </InputLabel>
            <Input
            required
              id="title"
              type="text"
              style={{ color: isDark ? "#fff" : "#000" }}
              defaultValue={fileURL !== "" ? fileURL.substring(0, fileURL.length - 4) : name}
              onChange={this.handleTitleChange}
              classes={{
                underline: isDark ? classes.underlineDark : classes.underline,
              }}
            />
          </FormControl>
          <br />

          <FormControl style={{ width: "70%",marginTop: '5%' }}>
            <InputLabel
              htmlFor="description"
              style={{ color: isDark ? "#fff" : "#000" }}
            >
              Description
            </InputLabel>
            <Input
            required
              id="description"
              type="text"
              style={{ color: isDark ? "#fff" : "#000" }}
              defaultValue={description}
              onChange={this.handleDescriptionChange}
              classes={{
                underline: isDark ? classes.underlineDark : classes.underline,
              }}
            />
          </FormControl>

        </Grid>
      </Grid>
    );
  }
}
StepperStepTwo.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  setDescription: PropTypes.func.isRequired,
  setName: PropTypes.func.isRequired,
  setAvatar: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  avatar: PropTypes.string.isRequired,
  fileURL: PropTypes.string.isRequired,
  isDark: PropTypes.bool.isRequired,
};
export default withStyles(styles)(StepperStepTwo);
