import React, { Component } from "react";
import Add from "@material-ui/icons/Add";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Fab from "@material-ui/core/Fab";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Apps from "@material-ui/icons/Apps";
import TileWidget from "./TileWidget";
import TableWidget from "./TableWidget";
import CarouselWidget from "./CarouselWidget";
import List from "@material-ui/icons/List";
import ViewCarousel from "@material-ui/icons/ViewCarousel";
import SelectedAssetWidget from "./SelectedAssetWidget";
import backgroundImage from "../assets/images/background-image1.png";


const styles = (theme) => ({
  root: theme.mixins.gutters({
    padding: theme.spacing(1),
    paddingBottom: 25,
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  }),
  title: {
    fontSize: 20,
    height:'100%',
    color: "#967258",
    width: '100%',
    borderTopRightRadius: '14px',
    backgroundImage: 'linear-gradient(296deg, #f19e774a, #f4f4f400)',
    display: 'inline',
    paddingRight: '8px',
    paddingLeft: '7px',
  },
  image: {
    width: "100%",
  },
  carousel: {
    position: "relative",
  },
  fabBtn: {
    right: 40,
    position: "absolute",
    backgroundColor: "#eeeeee",
    color: "#d94804",
    opacity: 1,
    fontSize: 30,
    "&:hover": {
      backgroundColor: "#fc7c28",
      color: "#ffffff",
    },
  },
  headerBtns: {
    float: "right",
    display: "inline",
    [theme.breakpoints.down("xs")]: {
      marginRight: "-13px",
    },
  },
  activeBtn: {
    borderBottom: "2px solid #d747045e",
    borderRadius: 0,
  },
});

class SalesView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTileView: false,
      isTableView: false,
      isCarouselView: true,
      isSelectedAssetOpen: false,
      selectedAsset: {},
    };
    this.handleClickTableView = this.handleClickTableView.bind(this);
    this.handleClickTileView = this.handleClickTileView.bind(this);
    this.handleClickCarouselView = this.handleClickCarouselView.bind(this);
  }
  componentDidUpdate(prevProps) {
    if (
      this.props.isAuctionViewExpanded !== prevProps.isAuctionViewExpanded ||
      this.props.isAssetViewExpanded !== prevProps.isAssetViewExpanded ||
      this.props.isSaleViewExpanded !== prevProps.isSaleViewExpanded ||
      this.props.isSaleSelected !== prevProps.isSaleSelected || 
      this.props.isMyAssetView !== prevProps.isMyAssetView
    ) {
      this.setState({
        isTileView:
          this.props.isSaleViewExpanded === true &&
          this.state.isTileView === true
            ? true
            : false,
        isTableView:
          this.props.isSaleViewExpanded === true &&
          this.state.isTableView === true
            ? true
            : false,
        isCarouselView:
          this.props.isAssetViewExpanded === true ||
          this.props.isAuctionViewExpanded === true
            ? true
            : false,
      });
      if (this.props.isSaleSelected === true) {
        this.setState({
          isTileView: true,
          isTableView: false,
          isCarouselView: false,
        });
      }
      if(this.props.isMyAssetView !== prevProps.isMyAssetView){
        this.setState({
          isTileView: false,
          isTableView: false,
          isCarouselView: true,
          isSelectedAssetOpen: false
        })
      }
    }
  }
  handleClickTableView() {
    this.setState({
      isTableView: true,
      isTileView: false,
      isCarouselView: false,
    });
    this.props.setExpandedView(false, true, false);
    this.props.setSaleSelected(false);
  }

  handleClickTileView() {
    this.setState({
      isTileView: true,
      isTableView: false,
      isCarouselView: false,
    });
    this.props.setExpandedView(false, true, false);
  }

  handleClickCarouselView() {
    this.setState({
      isTableView: false,
      isTileView: false,
      isCarouselView: true,
    });
    this.props.setExpandedView(false, false, true);
    this.props.setSaleSelected(false);
  }

  render() {
    const { classes, isSaleSelected, assets, isMyAssetView,isDark } = this.props;
    const {
      isTileView,
      isTableView,
      isCarouselView,
      isSelectedAssetOpen,
      selectedAsset,
    } = this.state;
    return isSelectedAssetOpen ? (
      
      <SelectedAssetWidget
        selectedAsset={selectedAsset}
        setIsSelectedAssetOpen={(isOpen) =>
          this.setState({ isSelectedAssetOpen: isOpen })
        }
        isDark={isDark}
      />
      
    ) : (
      <Paper className={classes.root} elevation={4} style={{backgroundColor: isDark ? '#222222' : '#ffffff'}}>
        <Typography type="title" className={classes.title} style={{color: isDark && 'rgb(217 213 213)'}}>
          {isMyAssetView ? "My Sales" : "All Sales"}
        </Typography>
        <div className={classes.headerBtns}>
          <Button
            onClick={this.handleClickTileView}
            className={isTileView ? classes.activeBtn : null}
          >
            <Apps style={{color: isDark && '#ffffff'}} />
          </Button>
          <Button
            onClick={this.handleClickTableView}
            className={isTableView ? classes.activeBtn : null}
          >
            <List style={{color: isDark && '#ffffff'}} />
          </Button>
          <Button
            onClick={this.handleClickCarouselView}
            className={isCarouselView ? classes.activeBtn : null}
          >
            <ViewCarousel style={{color: isDark && '#ffffff'}} />
          </Button>
        </div>
        {assets && isCarouselView && (
          <CarouselWidget
            assets={assets}
            setSelectedAsset={(asset, isOpen) =>
              this.setState({
                selectedAsset: asset,
                isSelectedAssetOpen: isOpen,
              })
            }
          />
        )}
        {assets && isTableView && (
          <TableWidget
            assets={assets}
            setSelectedAsset={(asset, isOpen) =>
              this.setState({
                selectedAsset: asset,
                isSelectedAssetOpen: isOpen,
              })
            }
            isDark={isDark}
          />
        )}
        {((assets && isTileView) || isSaleSelected) && (
          <TileWidget
            assets={assets}
            setSelectedAsset={(asset, isOpen) =>
              this.setState({
                selectedAsset: asset,
                isSelectedAssetOpen: isOpen,
              })
            }
            isDark={isDark}

          />
        )}
      </Paper>
    );
  }
}

SalesView.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  assets: PropTypes.array.isRequired,
  isMyAssetView: PropTypes.bool.isRequired,
  isAuctionViewExpanded: PropTypes.bool.isRequired,
  isSaleViewExpanded: PropTypes.bool.isRequired,
  isAssetViewExpanded: PropTypes.bool.isRequired,
  isSaleSelected: PropTypes.bool.isRequired,
  setExpandedView: PropTypes.func.isRequired,
  setSaleSelected: PropTypes.func.isRequired,
  isDark: PropTypes.func.isRequired,
};
export default withStyles(styles)(SalesView);
