import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  FormControlLabel,
  Select,
  FormControl,
  Grid,
  Input,
  MenuItem,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/styles";

const styles = (theme) => ({
  textField: {
    width: "10%",
  },
  input: {
    width: "15%",
  },
  switchRoot: {
    marginLeft: 15,
  },
  textFieldRootDark: {
    color: "#fff",
  },
  textFieldRoot: {
    color: "#000",
  },
  labelDark: {
    color: "#fff",
    "&$disabled": {
      color: "#afaeaf",
    },
  },
  label: {
    color: "#000",
    "&$disabled": {
      color: "#afaeaf",
    },
  },
  disabled:{},
  menuPaperDark:{
backgroundColor: '#000',
color: '#ffff'
}
});

const CustomSwitch = withStyles({
  switchBase: {
    "&$checked": {
      color: "#f2a516",
    },
    "&$checked + $track": {
      backgroundColor: "#f2a516",
    },
  },
  checked: {},
  track: {},
})(Switch);

class StepperStepThree extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleChangeFractions = this.handleChangeFractions.bind(this);
    this.handleCollectionType = this.handleCollectionType.bind(this);
    this.handlePreviewChange = this.handlePreviewChange.bind(this);
  }

  handleChangeFractions(event) {
    this.props.setFractions(event.target.value);
  }

  handleCollectionType(event) {
    this.props.setCollectionType(event.target.value);
  }
  handlePreviewChange(event) {
    this.props.setPreviewURL(event.target.value);
  }

  render() {
    const {} = this.state;
    const {
      classes,
      isFractional,
      isCollection,
      collectionType,
      isSale,
      isAuction,
      hasPreview,
      fractions,
      fileURL,
      setIsSale,
      setIsFractional,
      setIsCollection,
      setHasPreview,
      previewURL,
      isDark,
    } = this.props;

    return (
      <Grid container style={{ marginTop: "2%" }}>
        <Grid style={{ paddingLeft: 20 }} item xs={6} sm={6} md={6}>
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item>
              <FormControl>
                <FormControlLabel
                disabled
                  control={
                    <CustomSwitch
                      checked={isFractional}
                      onChange={() => setIsFractional(!isFractional)}
                      value={isFractional}
                    />
                  }
                  classes={{ label: isDark ? classes.labelDark : classes.label,disabled: classes.disabled }}
                  label="Fractional"
                  labelPlacement="start"
                />
              </FormControl>
            </Grid>
            <Grid item className={classes.input}>
              {isFractional ? (
                <FormControl className={classes.formControl}>
                  <FormControlLabel
                    control={
                      <TextField
                      disabled
                        type="number"
                        name="fractions"
                        value={fractions}
                        onChange={this.handleChangeFractions}
                        InputProps={{
                          classes: {
                            input: isDark
                              ? classes.textFieldRootDark
                              : classes.textFieldRoot,
                          },
                        }}
                        InputLabelProps={{
                          classes: {
                            root: isDark ? classes.labelDark : classes.label,
                          },
                        }}
                      />
                    }
                    // style={{ display: "inline-block", marginTop: "10px" }}
                    labelPlacement="start"
                  />
                </FormControl>
              ) : null}
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item>
              <FormControl>
                <FormControlLabel
                  control={
                    <CustomSwitch
                      disabled
                      checked={isCollection}
                      onChange={() => setIsCollection(!isCollection)}
                      value={isCollection}
                    />
                  }
                  classes={{ label: isDark ? classes.labelDark : classes.label,disabled: classes.disabled }}
                  label="Collection"
                  labelPlacement="start"
                />
              </FormControl>
            </Grid>
            <Grid item className={classes.input}>
              {isCollection ? (
                <FormControl className={classes.formControl}>
                  <FormControlLabel
                    control={
                      <Select
                        labelId="collection-type-label"
                        id="collection-type"
                        value={collectionType}
                        onChange={this.handleCollectionType}

                      >
                        <MenuItem value="album">Album</MenuItem>
                        <MenuItem value="playlist">Playlist</MenuItem>
                        <MenuItem value="folder">Folder</MenuItem>
                      </Select>
                    }
                    // style={{ display: "inline-block", marginTop: "10px" }}
                    labelPlacement="start"
                  />
                </FormControl>
              ) : null}
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item>
              <FormControl className={classes.formControl}>
                <FormControlLabel
                  control={
                    <Grid
                      component="label"
                      container
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid item style={{ paddingRight: 14 }} style={{color: isDark && '#afaeaf'}}>
                        Auction
                      </Grid>
                      <Grid item>
                        <CustomSwitch
                        disabled
                          checked={isSale}
                          onChange={() => setIsSale(!isSale)}
                          value={isSale}
                        />
                      </Grid>
                      <Grid item style={{color: isDark && '#fff'}}>Sale</Grid>
                    </Grid>
                  }
                  // style={{ display: "inline-block", marginTop: "10px" }}
                  labelPlacement="start"
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item>
              <FormControl>
                <FormControlLabel
                  control={
                    <CustomSwitch
                      checked={hasPreview}
                      onChange={() => setHasPreview(!hasPreview)}
                      value={hasPreview}
                      classes={{ root: classes.switchRoot }}
                    />
                  }
                  // style={{ display: "inline-block", marginTop: "10px" }}
                  label="Preview  "
                  labelPlacement="start"
                  classes={{ label: isDark ? classes.labelDark : classes.label, }}
                />
              </FormControl>
            </Grid>
            <Grid item>
              {hasPreview ? (
                <Input
                  // accept="image/*"
                  style={{ padding: 10 }}
                  id="raised-button-file"
                  type="file"
                  onChange={this.handlePreviewChange}
                />
              ) : null}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          {/* <img src={previewURL} /> */} //ToDo add player
        </Grid>
      </Grid>
    );
  }
}
StepperStepThree.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  setIsFractional: PropTypes.func.isRequired,
  setIsCollection: PropTypes.func.isRequired,
  setIsAuction: PropTypes.func.isRequired,
  setIsSale: PropTypes.func.isRequired,
  setHasPreview: PropTypes.func.isRequired,
  setFractions: PropTypes.func.isRequired,
  setFileURL: PropTypes.func.isRequired,
  setCollectionType: PropTypes.func.isRequired,
  setPreviewURL: PropTypes.func.isRequired,
  isFractional: PropTypes.bool.isRequired,
  isCollection: PropTypes.bool.isRequired,
  isSale: PropTypes.bool.isRequired,
  isAuction: PropTypes.bool.isRequired,
  hasPreview: PropTypes.bool.isRequired,
  fractions: PropTypes.number.isRequired,
  fileURL: PropTypes.string.isRequired,
  previewURL: PropTypes.string.isRequired,
  collectionType: PropTypes.string.isRequired,
  isDark: PropTypes.bool.isRequired,
};
export default withStyles(styles)(StepperStepThree);
