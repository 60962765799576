import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import CarouselView from "./CarouselView";
import AliceCarousel from "react-alice-carousel";

const styles = (theme) => ({
  carousel: {
    marginTop: "7%",
    [theme.breakpoints.down('xs')]:{
      marginLeft: '29%'
    }
  },
  iconButton: {
    "&:hover": {
      backgroundColor: "rgb(251 251 251);",
      color: "#d94804",
    },
  },
});
class CarouselWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes, assets, setSelectedAsset } = this.props;


    const responsive = {
      0: { items: 1 },
      568: { items: 2 },
      854: { items: 3 },
      960: { items: 4 },
      1024: { items: 5 },
      1366: { items: 6 },
    };
    return (
      <div className={classes.carousel}>
        <AliceCarousel
          mouseTracking
          autoPlay={assets.length <= 3 ? false : true}
          infinite
          // paddingLeft={assets.length < 4 ? 150 : 50}
          disableButtonsControls
          autoPlayInterval={1500}
          animationDuration={1000}
          responsive={responsive}
          items={assets.map((asset) => (
            <CarouselView
              asset={asset}
              key={asset._id}
              setSelectedAsset={setSelectedAsset}
            />
          ))}
        />
        
      </div>
    );
  }
}

CarouselWidget.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  assets: PropTypes.array.isRequired,
  setSelectedAsset: PropTypes.func.isRequired,
};
export default withStyles(styles)(CarouselWidget);
