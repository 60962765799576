import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  withStyles,
  createStyles,
  styled,
} from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuList from "@material-ui/core/Menu";
import Tooltip from "@material-ui/core/Tooltip";
import GavelIcon from "@material-ui/icons/Gavel";
import MoreIcon from "@material-ui/icons/MoreVert";
import LocalOffer from "@material-ui/icons/LocalOffer";
import PersonIcon from "@material-ui/icons/Person";
import Badge from "@material-ui/core/Badge";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import logo from "../assets/images/logo.png";
import algorandLogo from "../assets/images/algorand.png";
import algorandLogoWhite from "../assets/images/algorand_white.png";
import CustomDrawer from "./CustomDrawer";
import whiteLogo from "../assets/images/logo-white.png";
import Switch from "@material-ui/core/Switch";
import user from "../assets/images/user.png";
import users from "../assets/images/users.png";

const styles = (theme) => ({
  logo: {
    height: 60,
    width: 60,
  },
  appbar: {
    backgroundColor: "#ffffff",
    boxShadow: "0px 1px 21px -1px #d948044d",
    [theme.breakpoints.down("xs")]: {
      backgroundColor: "#333333",
    },
  },
  appbarDark: {
    backgroundColor: "#1B1B1B",
    boxShadow: "0px 1px 21px -1px #d948044d",
    [theme.breakpoints.down("xs")]: {
      backgroundColor: "#333333",
    },
  },
  title: {
    marginLeft: 10,
    fontSize: 30,
    backgroundImage: "linear-gradient(left, #d94804, #4f4f4f, #c0c0c0)",
    // backgroundImage: '-moz-linear-gradient(left, #d94804, #828282, #c0c0c0)',
    // backgroundImage: '-ms-linear-gradient(left, #d94804, #828282, #c0c0c0)',
    // backgroundImage: '-o-linear-gradient(left, #d94804, #828282, #c0c0c0)',
    color: "transparent",
    "-webkit-background-clip": "text",
  },
  btn: {
    backgroundColor: "rgba(0, 0, 0, 0.04)",
    color: "#4f4f4f",
    borderRadius: 20,
    marginRight: 5,
    marginLeft: 10,
    "&:hover": {
      /* backgroundColor: "#fc7c28", */
      backgroundImage: 'linear-gradient(172deg, #e27d4e, #faf6f4) ',
      color: "#ffffff",
    },
  },
  icon: {
    "&:hover": {
      color: "#ffffff",
    },
  },
  iconDark: {
    color: "#ffffff",
    "&:hover": {
      color: "#ffffff",
    },
  },
  moreIconBtn: {
    position: "absolute",
    right: 0,
    display: "none",
    color: "#ffffff",
    "&:hover": {
      /* backgroundColor: "#fc7c28", */
      backgroundImage: 'linear-gradient(172deg, #e27d4e, #faf6f4) ',
      color: "#ffffff",
    },
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  switchBtn: {
    position: "absolute",
    right: 50,
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "inline-flex",
    },
  },
  algoIcon: {
    width: '24px',
  },
  menuItem: {
    color: "#333333",
    "&:hover": {
      backgroundColor: "#f2a516",
      color: "#ffffff",
    },
  },
  menuLink: {
    color: "#333333",
    "&:hover": {
      backgroundColor: "#f2a516",
      color: "#ffffff",
    },
  },
  iconButton: {
    marginLeft: 10,
    "&:hover": {
      /* backgroundColor: "#fc7c28", */
      backgroundImage: 'linear-gradient(172deg, #e27d4e, #faf6f4) ',
      color: "#ffffff",
    },
  },
  activeButton: {
    backgroundColor: '#fba76f99'
  },
  menuIconButton: {
    "&:hover": {
      color: "#fc7c28",
    },
  },
  linkBtn: {
    fontSize: "0.8em",
    "&:hover": {
      color: "#fc7c28",
      backgroundColor: "#ffffff",
    },
  },
  linksDiv: {
    marginTop: "9px",
    [theme.breakpoints.down("lg")]: {
      marginLeft: "35px",
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: "30px",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "20px",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  iconsDiv: {
    position: "absolute",
    right: "10px",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  menuRoot:{
    backgroundColor: '#222222'
  }
});

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 52,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      marginLeft: 11,
      "& + .MuiSwitch-track": {
        backgroundColor: "#cac9c9",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#f44336",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.7,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
    backgroundRepeat: "no-repeat",
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#cac9c9",
    opacity: 1,
    [theme.breakpoints.down("xs")]: {
      backgroundColor: "#E9E9EA",
    },
    "&:after, &:before": {
      color: "white",
      fontSize: "11px",
      position: "absolute",
      top: "6px",
    },
    "&:after": {
      content: `url(${user})`,
      backgroundImage: `url(${user})`,
      backgroundSize: "17px",
      backgroundRepeat: "no-repeat",
      left: "4px",
      top: "4px",
    },
    "&:before": {
      content: "''",
      backgroundImage: `url(${users})`,
      backgroundSize: "17px",
      backgroundRepeat: "no-repeat",
      right: "5px",
      top: "4px",
      padding: 9,
    },
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const StyledBadge = withStyles((theme) =>
  createStyles({
    badge: {
      right: "5px",
      top: "-7px",
      /* border: "2px solid #d94804", */
      padding: "0 4px",
      backgroundColor: "#d94804",
      backgroundImage: 'linear-gradient(45deg,  rgba(250, 83, 5, 0.78), rgba(94, 90, 90, 0.86))',
      color: "#ffffff",
    },
  })
)(Badge);

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      isDrawerOpen: false,
      selectedItem: "",
    };
    this.handleClickMore = this.handleClickMore.bind(this);
    this.handleCloseMore = this.handleCloseMore.bind(this);
    this.handleClickAvatar = this.handleClickAvatar.bind(this);
    this.handleCloseDrawer = this.handleCloseDrawer.bind(this);
    this.handleClickIcon = this.handleClickIcon.bind(this);
    this.handleClickSwitch = this.handleClickSwitch.bind(this);
    this.handleAuctionClick = this.handleAuctionClick.bind(this);
  }

  handleClickMore(event) {
    this.setState({ anchorEl: event.currentTarget });
  }

  handleCloseMore() {
    this.setState({ anchorEl: null });
  }

  handleClickAvatar(event) {
    this.setState({ isDrawerOpen: !this.state.isDrawerOpen });

  }

  handleCloseDrawer() {
    this.setState({
      isDrawerOpen: false,
    });
  }

  handleClickIcon(selectedItem) {
    this.setState({
      isDrawerOpen: !this.state.isDrawerOpen,
      selectedItem: selectedItem,
    });
  }

  handleClickSwitch() {
    const { setView, isMyAssetView, setAuctionSelected, setSaleSelected } = this.props
    setView(!isMyAssetView)
    setAuctionSelected(false)
    setSaleSelected(false)

  }
  handleAuctionClick() {
    this.props.setAuctionSelected(!this.props.isAuctionSelected)
  }

  render() {
    const { classes, isMyAssetView, setAuctionSelected, setSaleSelected, isSaleSelected, isAuctionSelected,isDark,setDarkMode } = this.props;
    const { anchorEl, isDrawerOpen, selectedItem, checked } = this.state;

    return (
      <>
        <AppBar position="static" className={isDark ? classes.appbarDark : classes.appbar}>
          <Toolbar>
            <Avatar src={logo} classes={{ root: classes.logo }} />
            <Typography variant="h6" color="inherit" className={classes.title}>
              DisKoin
            </Typography>

            <IconButton
              aria-controls="navbar-menu"
              aria-label="More"
              className={classes.moreIconBtn}
              onClick={this.handleClickMore}
            >
              <MoreIcon className={isDark ? classes.iconDark : classes.icon} />
            </IconButton>
            <IOSSwitch
              sx={{ m: 1 }}
              className={classes.switchBtn}
              checked={isMyAssetView}
              onChange={this.handleClickSwitch}
            />

            <MenuList
              id="navbar-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={this.handleCloseMore}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              classes={{paper: isDark && classes.menuRoot}}
            >
              <ButtonGroup variant="text" aria-label="text button group">
                <IconButton
                  className={classes.menuIconButton}
                  onClick={this.handleAuctionClick}
                >
                  <StyledBadge invisible={false} badgeContent={32}>
                    <GavelIcon style={{color: isDark && '#fff'}} />
                  </StyledBadge>
                </IconButton>
                <IconButton
                  className={classes.menuIconButton}
                  onClick={() => setSaleSelected(!isSaleSelected)}
                >
                  <StyledBadge invisible={false} badgeContent={2}>
                    <LocalOffer style={{color: isDark && '#fff'}} />
                  </StyledBadge>
                </IconButton>
                <IconButton
                  className={classes.menuIconButton}
                  onClick={() => this.handleClickIcon("algo")}
                >
                  <StyledBadge invisible={false} badgeContent={273}>
                    <img src={isDark ? algorandLogoWhite : algorandLogo} className={classes.algoIcon} />
                  </StyledBadge>
                </IconButton>
                <IconButton
                  aria-controls="user-menu"
                  onClick={this.handleClickAvatar}
                  className={classes.menuIconButton}
                  onClick={() => this.handleClickIcon("profile")}
                >
                  <Avatar src={<PersonIcon size="small" />} />
                </IconButton>
              </ButtonGroup>
            </MenuList>
            <div className={classes.iconsDiv}>
              <span style={{ float: "right" }}>
                <IOSSwitch sx={{ m: 1 }}
                  checked={isMyAssetView}
                  onChange={this.handleClickSwitch}
                />

                <Tooltip title="Auctions" arrow>
                  <IconButton
                    className={isAuctionSelected ? classes.activeButton : classes.iconButton}
                    onClick={() => setAuctionSelected(!isAuctionSelected)}
                  >
                    <StyledBadge invisible={false} badgeContent={0}>
                      <GavelIcon className={isDark ? classes.iconDark : classes.icon} />
                    </StyledBadge>
                  </IconButton>
                </Tooltip>

                <Tooltip title="Sales" arrow>
                  <IconButton
                    className={isSaleSelected ? classes.activeButton : classes.iconButton}
                    onClick={() => setSaleSelected(!isSaleSelected)}
                  >
                    <StyledBadge invisible={false} badgeContent={2}>
                      <LocalOffer className={isDark ? classes.iconDark : classes.icon} />
                    </StyledBadge>
                  </IconButton>
                </Tooltip>

                <Tooltip title="Algo" arrow>
                  <IconButton
                    className={classes.iconButton}
                    onClick={() => this.handleClickIcon("algo")}
                  >
                    <StyledBadge invisible={false} badgeContent={273}>
                      <img src={isDark ? algorandLogoWhite : algorandLogo} className={classes.algoIcon} />
                    </StyledBadge>
                  </IconButton>
                </Tooltip>

                <Tooltip title="User menu" arrow>
                  <IconButton
                    aria-controls="user-menu"
                    onClick={this.handleClickAvatar}
                    className={classes.iconButton}
                    onClick={() => this.handleClickIcon("profile")}
                  >
                    <Avatar src={<PersonIcon className={isDark ? classes.iconDark : classes.icon} />} />
                  </IconButton>
                </Tooltip>
              </span>
            </div>
          </Toolbar>
        </AppBar>
        <CustomDrawer
          isDrawerOpen={isDrawerOpen}
          closeDrawer={() => this.handleCloseDrawer}
          selectedItem={selectedItem}
          isDark={isDark}
          setDarkMode={setDarkMode}
        />
      </>
    );
  }
}
Header.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  setView: PropTypes.func.isRequired,
  isMyAssetView: PropTypes.bool.isRequired,
  isAuctionSelected: PropTypes.bool.isRequired,
  isSaleSelected: PropTypes.bool.isRequired,
  setSaleSelected: PropTypes.func.isRequired,
  setAuctionSelected: PropTypes.func.isRequired,
  setDarkMode: PropTypes.func.isRequired,
  isDark: PropTypes.bool.isRequired,
};
export default withStyles(styles)(Header);
