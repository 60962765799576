import React, { Component } from "react";
import PropTypes from "prop-types";
import { InputAdornment ,FormControl, Input, InputLabel} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Add from "@material-ui/icons/Add";
import Delete from "@material-ui/icons/Delete";
import algo from "../assets/images/algo.png";
import algoWhite from "../assets/images/algo-white.png";
import { withStyles } from "@material-ui/styles";
import ChipInput from "./CheapInput";

const styles = (theme) => ({
  textField: {
    width: "50%",
  },
  textFieldRootDark: {
    color: "#fff",
  },
  textFieldRoot: {
    color: "#000",
  },
  labelDark: {
    color: "#9f9d9d",
  },
  label: {
    color: "#000",
  },
  labelDark: {
    color: "#9f9d9d",
  },
  label: {
    color: "#000",
  },
  underlineDark: {
    borderBottom: "1px solid #ffffff",
    "&:before": {
      borderBottom: "1px solid #fff",
    },
    "&:after": {
      borderBottom: "2px solid #fff",
    },
  },
  underline: {
    borderBottom: "1px solid #000000",
  },
});

class StepperStepFive extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handlePriceChange = this.handlePriceChange.bind(this);
    this.handleInitialPriceChange = this.handleInitialPriceChange.bind(this);
    this.handleChangeTag = this.handleChangeTag.bind(this);
  }

  handlePriceChange(event) {
    this.props.setPrice(event.target.value);
  }

  handleInitialPriceChange(event) {
    this.props.setInitialPrice(event.target.value);
  }
  handleChangeTag(tags) {
    this.setState({
      tags: JSON.stringify(tags),
    });
  }

  render() {
    const {} = this.state;
    const { classes, initialPrice, tags, price, isSale, isDark} = this.props;

    return (
      <div>
        {isSale ? (

          <FormControl style={{ width: "80%", marginTop: "5%" }}>
            <InputLabel
              htmlFor="price"
              style={{ color: isDark ? "#fff" : "#000" }}
            >
              Price
            </InputLabel>
            <Input
              id="price"
              type="number"
              style={{ color: isDark ? "#fff" : "#000" }}
              className={classes.textField}
              defaultValue={price}
              onChange={this.handlePriceChange}
              classes={{
                underline: isDark ? classes.underlineDark : classes.underline,
              }}
              endAdornment={
              <InputAdornment position="end">
                <img width={22} height={22} src={isDark ? algoWhite : algo} />
              </InputAdornment>
            }

           
            />
          </FormControl>



          
        ) : (
          <TextField
            label="Initial price"
            placeholder="Initial price"
            type="text"
            margin="normal"
            className={classes.textField}
            value={initialPrice}
            onChange={this.handleInitialPriceChange}
            InputProps={{
              endAdornment: <img width={22} height={22} src={isDark ? algoWhite : algo} />,
              classes: {
                input: isDark
                  ? classes.textFieldRootDark
                  : classes.textFieldRoot,
              },
            }}
            InputLabelProps={{
              classes: {
                root: isDark ? classes.labelDark : classes.label,
              },
            }}
          />
        )}

        <br />
        <FormControl style={{ width: "80%", marginTop: "5%" }}>
        <ChipInput
          defaultValue={tags !== "" ? JSON.parse(tags) : ""}
          style={{ width: "51%",COLOR: isDark && classes.labelDark }}
          onChange={(tags) => this.handleChangeTag(tags)}
          label="Tags"
          classes={{
                underline: isDark ? classes.underlineDark : classes.underline,label: isDark && classes.labelDark
              }}
        />
        </FormControl>
      </div>
    );
  }
}
StepperStepFive.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  price: PropTypes.number.isRequired,
  initialPrice: PropTypes.number.isRequired,
  tags: PropTypes.array.isRequired,
  setPrice: PropTypes.func.isRequired,
  setInitialPrice: PropTypes.func.isRequired,
  setTags: PropTypes.func.isRequired,
  isSale: PropTypes.bool.isRequired,
  isAuction: PropTypes.bool.isRequired,
  isDark: PropTypes.bool.isRequired,
};
export default withStyles(styles)(StepperStepFive);
